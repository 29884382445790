<template>
  <v-dialog v-if="!loading" v-model="exibeModal" scrollable persistent>
    <v-card>
      <v-card-title class="modal-header elevation-10">
        <v-col>
          <slot name="title">
            <span style="font-size: 30px"
              ><b>{{ municipio.nome }} - {{ municipioLocal.uf }}</b></span
            >
          </slot>
        </v-col>
        <v-col class="text-right">
          <v-icon color="white" @click="$emit('closeModal')">
            mdi-window-close
          </v-icon>
        </v-col>
      </v-card-title>
      <div>
        <v-card-text>
          <v-row v-if="municipioLocal.relacionamento && !isSala">
            <v-col>
              <b>Relacionamento: </b> {{ municipioLocal.relacionamento }}
            </v-col>
          </v-row>
          <v-row v-if="municipioLocal.contrato && !isSala">
            <!--v-col> <b>Contrato: </b> {{ municipioLocal.contrato }} </v-col-->
            <v-col> <b>Subsídio: </b> {{ municipioLocal.subsidio }} </v-col>
          </v-row>
          <v-row v-if="municipioLocal.contratoInicio && !isSala">
            <v-col> <b>Início: </b> {{ municipioLocal.contratoInicio }} </v-col>
            <v-col> <b>Fim: </b> {{ municipioLocal.contratoFim }} </v-col>
          </v-row>

          <v-row v-if="municipio.regiao">
            <v-col>
              <span> <b>Região: </b> {{ municipio.regiao.nome }} </span>
            </v-col>
          </v-row>

          <v-row v-if="municipioLocal.projetoId">
            <v-col>
              <span> <b>Projeto: </b> {{ municipioLocal.nomeProjeto }} </span>
            </v-col>
          </v-row>

          <v-row v-if="municipioLocal.temas">
            <v-col>
              <v-tabs v-model="tab" background-color="#5F9EA0" dark centered>
                <v-tab v-for="item in itens" :key="item.titulo" center>
                  {{ item.titulo }}
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item v-for="item in itens" :key="item.titulo">
                  <!-- DADOS GERAIS  -->
                  <v-row
                    v-if="item.titulo === 'Dados Gerais'"
                    class="rowDadosGerais"
                  >
                    <v-col class="primary">
                      <b>
                        {{ isSala ? "Critérios" : "Questões" }} respondid{{
                          isSala ? "o" : "a"
                        }}s SIM:
                      </b>
                      {{ qntdadePerguntasSim }}%
                    </v-col>

                    <v-col class="primary">
                      <b>Questões Evidenciadas:</b>
                      {{ qntdadePerguntasEvidenciada }}%
                    </v-col>
                  </v-row>
                  <!-- TEMAS PONTOS  -->
                  <v-row v-if="item.titulo === 'Dados Gerais'">
                    <v-col>
                      <v-data-table
                        :headers="
                          (uf !== 'NA' && cabecalhoTemasPontos) ||
                          cabecalhoTemasPontosNA
                        "
                        :items="municipioLocal.temas"
                        :disable-pagination="true"
                        :hide-default-footer="true"
                      >
                        <template #item.medalha="{ item }" v-if="uf !== 'NA'">
                          <img
                            v-if="item.tipoMedalha"
                            style="width: 100px"
                            :src="
                              'data:image/png;base64,' +
                              item.medalhas[0][`imagem${item.tipoMedalha}Blob`]
                            "
                          />
                        </template>
                        <template #item.percentualAtingido="{ item }">
                          {{ parseInt(item.percentualAtingido) }}%
                        </template>
                        <template slot="no-data">
                          <span>
                            Nenhum {{ isSala ? "pilar" : "tema" }} retornado
                          </span>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                  <!-- FIM DOS TEMAS PONTOS  -->

                  <!-- PERGUNTAS  -->
                  <!-- SOMENTE PERGUNTAS DO GRUPO 0 -->
                  <v-row v-if="item.titulo !== 'Dados Gerais'">
                    <v-col>
                      <v-data-table
                        :headers="cabecalhoPerguntasPrincipais"
                        :items="filtrarPerguntasPrincipais(item.titulo)"
                        :disable-pagination="true"
                        :hide-default-footer="true"
                      >
                        <template v-slot:item.resposta="{ item }">
                          <div
                            v-if="
                              item.resposta === true &&
                              item.aprovada === false &&
                              item.evidencia
                            "
                            align="center"
                          >
                            <table style="background-color: #0489b1">
                              <tr>
                                <td align="center">
                                  <span style="color: white"
                                    >{{
                                      isSala ? "Não" : "Sem evidência aprovada"
                                    }}
                                  </span>
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div
                            v-if="
                              item.resposta === true &&
                              item.aprovada === true &&
                              item.evidencia
                            "
                            align="center"
                          >
                            <table style="background-color: #04b486">
                              <tr>
                                <td align="center">
                                  <span style="color: white">{{
                                    isSala ? "SIM" : "EVIDÊNCIA APROVADA"
                                  }}</span>
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div
                            v-if="item.resposta === true && !item.evidencia"
                            align="center"
                          >
                            <table style="background-color: #04b486">
                              <tr>
                                <td align="center">
                                  <span style="color: white">SIM</span>
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div v-if="item.resposta === false" align="center">
                            <table style="background-color: #ff6347">
                              <tr>
                                <td align="center">
                                  <span style="color: white">NÃO</span>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </template>

                        <template slot="no-data">
                          <span> Nenhuma pergunta retornada </span>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                  <!-- FIM PERGUNTAS  -->
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
          <v-row v-if="!municipioLocal.temas">
            MUNICÍPIO AINDA SEM DADOS PARA EXIBIÇÃO
          </v-row>
        </v-card-text>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { municipioAPI, perguntaAPI } from "@/modules/game";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
  props: {
    municipio: { type: Object, required: true },
    exibeModal: { type: Boolean, required: true },
  },
  computed: {
    ...mapGetters(["loading", "isSala"]),
  },
  data() {
    return {
      uf: this.$store.state.uf,
      cabecalhoEncontros: [
        { text: "Consultor", value: "nome" },
        { text: "Encontros Agendados", value: "encontros" },
        { text: "Relatórios Inseridos", value: "relatorios" },
      ],
      encontros: [],
      cabecalhoTemasPontos: [
        { text: "Tema", value: "nomeTema" },
        { text: "Medalhas", value: "medalha" },
        { text: "Evolução", value: "percentualAtingido" },
      ],
      cabecalhoTemasPontosNA: [
        { text: "Tema", value: "nomeTema" },
        { text: "Evolução", value: "percentualAtingido" },
      ],
      temasPontos: [],
      itens: [{ titulo: "Dados Gerais", texto: "1" }],
      tab: 0,
      perguntasRespondidas: [],
      perguntasEvidenciadas: [],
      cabecalhoPerguntasPrincipais: [
        { text: "Indicador", value: "texto" },
        { text: "Resposta", value: "resposta" },
      ],
      cabecalhoPerguntasDerivadas: [
        { text: "Indicador", value: "texto" },
        { text: "Resposta", value: "indicadorResposta" },
      ],
      perguntasPrincipais: [],
      perguntasDerivadas: [],
      municipioLocal: {
        nome: "",
        projeto: {},
        regiao: {},
        questoesSim: 0,
        questoesEvidenciadas: 0,
      },
      qntdadePerguntasSim: 0,
      qntdadePerguntasEvidenciada: 0,
    };
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getMunicipio() {
      await this.setLoading(true);
      municipioAPI
        .getPainel(this.$store.state.uf, this.municipio.codigoIBGE)
        .then(async (resp) => {
          if (resp.data.length) {
            this.municipioLocal = {
              temas: resp.data,
              nome: resp.data[0].nomeMunicipio,
              nomeProjeto: resp.data[0].nomeProjeto,
              contratoInicio: resp.data[0].contratoInicio,
              contratoFim: resp.data[0].contratoFim,
              contrato: resp.data[0].contrato,
              relacionamento: resp.data[0].relacionamento,
              subsidio: resp.data[0].subsidio,
              uf: resp.data[0].uf,
            };

            if (this.uf !== "NA") {
              const { data: medalhas } = await this.getMedalha();
              this.manipulateMedalhas(medalhas);
            }

            if (resp.data[0].contratoInicio == "0001-01-01 00:00:00") {
              this.municipioLocal.contratoInicio = "Sem data de contrato";
            } else {
              this.municipioLocal.contratoInicio = moment(
                this.municipioLocal.contratoInicio
              ).format("DD.MM.YYYY");
            }
            if (resp.data[0].contratoFim == "0001-01-01 00:00:00") {
              this.municipioLocal.contratoFim = "Sem data de contrato";
            } else {
              this.municipioLocal.contratoFim = moment(
                this.municipioLocal.contratoFim
              ).format("DD.MM.YYYY");
            }
            this.itens = [{ titulo: "Dados Gerais", texto: "1" }];
            for (
              let count = 0;
              count < this.municipioLocal.temas.length;
              count++
            ) {
              this.itens.push({
                titulo: this.municipioLocal.temas[count].nomeTema,
                texto: count + 2,
              });
            }

            let qntSim = this.municipioLocal.temas.reduce(
              (partialSum, a) => partialSum + a.sim,
              0
            );
            let qntNao = this.municipioLocal.temas.reduce(
              (partialSum, a) => partialSum + a.nao,
              0
            );
            let qntEvidencia = this.municipioLocal.temas.reduce(
              (partialSum, a) => partialSum + a.evidenciadas,
              0
            );
            this.getQntdadePerguntasSim(qntSim, qntNao);
            this.getQntdadePerguntasEvidenciada(qntSim, qntNao, qntEvidencia);

            this.getPerguntas();
          } else {
            this.municipioLocal = {
              nome: "",
              projeto: {},
              regiao: {},
              questoesSim: 0,
              questoesEvidenciadas: 0,
            };
            this.setLoading(false);
          }
        })
        .catch(() => {
          // console.error('ERROR on ModalIndicadorGame municipioAPI.getPainel: ', resp)

          this.setLoading(false);
        });
    },
    manipulateMedalhas(medalhas) {
      this.municipioLocal.temas.forEach((tema) => {
        const medalhasTemas = medalhas.filter(
          (medalha) => medalha.temaId === tema.temaId
        );

        const medalhasTemasOuro = medalhasTemas.find((item) => item.possuiOuro);
        const medalhasTemasPrata = medalhasTemas.find(
          (item) => item.possuiPrata
        );
        const medalhasTemasBronze = medalhasTemas.find(
          (item) => item.possuiBronze
        );
        if (medalhas.length && medalhasTemas) {
          tema.medalhas = medalhasTemas;
          tema.tipoMedalha = medalhasTemasOuro
            ? "Ouro"
            : medalhasTemasPrata
            ? "Prata"
            : medalhasTemasBronze
            ? "Bronze"
            : null;
        }
      });
    },
    async getMedalha() {
      return await municipioAPI.getMedalhas(this.municipio.codigoIBGE);
    },
    getQntdadePerguntasSim(sim, nao) {
      if (sim + nao > 0) {
        this.qntdadePerguntasSim = Math.round((sim / (sim + nao)) * 100);
      }
    },
    getQntdadePerguntasEvidenciada(sim, nao, evidencia) {
      if (sim + nao > 0) {
        this.qntdadePerguntasEvidenciada = Math.round(
          (evidencia / (sim + nao)) * 100
        );
      }
    },
    async getPerguntas() {
      await perguntaAPI
        .getPerguntasMunicipio(this.municipio.codigoIBGE)
        .then((resp) => {
          this.perguntasPrincipais = resp.data.filter(
            (p) => p.grupo === 0 || p.grupo !== 0
          );
          this.perguntasDerivadas = resp.data.filter(
            (p) => p.nivel === 1 && p.resposta === 1
          );
          this.setLoading(false);
        })
        .catch(() => {
          // console.error('ERROR on ModalIndicadorGame perguntaAPI.getPerguntasMunicipio: ', resp)
          this.setLoading(false);
        });
    },
    filtrarPerguntasPrincipais(tema) {
      return this.perguntasPrincipais.filter((p) => {
        return p.master === true && p.temasNome === tema;
      });
    },
    filtrarPerguntasDerivadas(tema) {
      return this.perguntasDerivadas.filter((p) => {
        return (
          p.temasNome === tema &&
          (p.nivel === 1 || (p.nivel > 1 && p.resposta === true))
        );
      });
    },
  },
  watch: {
    exibeModal: async function (value) {
      if (value) {
        await this.getMunicipio();
      }
    },
  },
  mounted() {
    if (this.isSala) {
      this.cabecalhoTemasPontos = [
        { text: "Pilar", value: "nomeTema" },
        { text: "Medalhas", value: "medalha" },
        { text: "Evolução", value: "percentualAtingido" },
      ];

      this.cabecalhoTemasPontosNA = [
        { text: "Pilar", value: "nomeTema" },
        { text: "Evolução", value: "percentualAtingido" },
      ];

      this.cabecalhoPerguntasPrincipais = [
        { text: "Critério", value: "texto" },
        { text: "Resposta", value: "resposta" },
      ];
      this.cabecalhoPerguntasDerivadas = [
        { text: "Critério", value: "texto" },
        { text: "Resposta", value: "indicadorResposta" },
      ];
    }
  },
};
</script>

<style lang="scss">
.rowDadosGerais {
  margin-left: 0;
  margin-right: 0;
  margin-top: 20px;
  color: #fff;
}
.rowDadosGeraisValores {
  margin-left: 0;
  margin-right: 0;
  color: #fff;
}
</style>
