<template>
  <v-select
    id="pergunta-master-select"
    v-model="filter.indicador"
    item-name="perguntaId"
    item-text="textoCompleto"
    :items="perguntas"
    :label="isSala ? 'Critérios' : 'Indicador'"
    placeholder="Selecione um Indicador"
    :return-object="true"
    :disabled="disabled"
    @change="emitValue"
    multiple
  >
    <div
      style="display: flex; padding: 0 16px; cursor: pointer"
      slot="prepend-item"
      ripple
      @click="toggle"
    >
      <div style="margin-right: 32px">
        <v-icon :color="filter.indicador.length > 0 ? 'indigo darken-4' : ''">{{
          icon
        }}</v-icon>
      </div>
      <div>Todos</div>
    </div>
    <v-divider slot="prepend-item" class="mt-2" />
    <template v-slot:selection="{ item, index }">
      <v-chip v-bind="item" small v-if="index < maxDisplay">
        <span class="textoTemas">{{
          diminuirValue(item.nomeTemas + "-" + item.texto)
        }}</span>
      </v-chip>
      <span v-if="index === maxDisplay" class="grey--text caption"
        >(+{{ filter.indicador.length - maxDisplay }}
        {{ isSala ? "critérios" : "indicadores" }})</span
      >
    </template>

    <template slot="no-data">
      Nenhum {{ isSala ? "Critério" : "Indicador" }} retornado
    </template>
  </v-select>
</template>

<script>
import { perguntaAPI } from "@/modules/game";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AppPerguntasMasterCombobox",
  props: {
    filter: { type: Object, required: true },
    disabled: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      perguntas: [],
      todasPerguntas: [],
      maxDisplay: 1,
    };
  },
  computed: {
    likesAll() {
      return this.filter.indicador.length === this.todasPerguntas.length;
    },
    likesSome() {
      return this.filter.indicador.length > 0 && !this.todasPerguntas;
    },
    icon() {
      if (this.likesAll) return "mdi-close-box";
      if (this.likesSome) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    ...mapGetters(["isSala"]),
  },
  watch: {
    filter: {
      handler: function (newValue) {
        this.perguntas = this.todasPerguntas;
        if (newValue.eixo && newValue.eixo.length) {
          this.perguntas = this.perguntas.filter((item) =>
            newValue.eixo.some((e) => e.temaId === item.temaId)
          );
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getPerguntasMaster();
  },
  methods: {
    diminuirValue(item) {
      if (item.length > 10) {
        return item.substring(0, 10) + "...";
      }

      return item;
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAll) {
          this.filter.indicador = [];
          this.$emit("changePerguntasMaster", this.filter.indicador);
        } else {
          this.filter.indicador = this.perguntas.slice();
          this.$emit("changePerguntasMaster", this.filter.indicador);
        }
      });
    },
    emitValue(e) {
      this.$emit("changePerguntasMaster", e);
    },
    getPerguntasMaster() {
      perguntaAPI
        .getPerguntasMaster(this.$store.state.uf)
        .then((resp) => {
          this.perguntas = resp.data.map((item) => {
            return {
              ...item,
              textoCompleto: `${item.nomeTemas} - ${item.texto}`,
            };
          });
          this.perguntas.sort((a, b) => {
            return a.temaId - b.temaId;
          });
          this.todasPerguntas = this.perguntas;
        })
        .catch(() => {
          this.$notification.error("Erro ao listar indicadores");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.textoTemas {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
