<template>
  <v-select
    id="regiao-select"
    v-model="filter.regiao"
    item-text="nome"
    item-name="regiaoId"
    :items="regioes"
    label="Região"
    placeholder="Selecione uma Região"
    :return-object="true"
    :disabled="disabled"
    @change="$emit('changeRegiao')"
    :multiple="isMultiple"
  >
    <template slot="no-data">
      Nenhuma Região retornada
    </template>
  </v-select>
</template>

<script>
// import { regiaoAPI } from '@/modules/regiao'
import { municipioAPI } from '@/modules/municipio'

export default {
  name: 'AppRegiaoCombobox',
  props: {
    filter: { type: Object, required: true },
    disabled: { type: Boolean, required: false, default: false },
    isMultiple: { type: Boolean, required: false, default: false }

  },
  data () {
    return {
      regioes: []
    }
  },
  mounted () {
    this.list()
  },
  methods: {
    list () {
      /*
      regiaoAPI.list()
        .then(resp => {
          this.regioes = resp.data
        })
        .catch(resp => {
          this.$notification.error('Erro ao listar regiões')
        })
      */
      municipioAPI.getRegionais()
        .then(resp => {
          this.regioes = resp.data
        })
        .catch(() => {
          this.$notification.error('Erro ao listar regiões')
        })
    }
  }
}
</script>
