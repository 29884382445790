<template>
  <v-container>
    <v-row v-if="$store.state.uf != 'BA'">
      <v-col>
        <app-regiao-combobox
          ref="regiaoSelect"
          :filter="payload"
          :disabled="payload.municipio.municipioId > 0"
          @changeRegiao="getByRegional(payload.regiao.regiaoSebraeId)"
        />
      </v-col>

      <v-col>
        <template>
          <v-select
            id="projeto-select"
            v-model="projetoId"
            :filter="payload"
            item-text="nome"
            item-name="projetoId"
            :items="projetos"
            label="Projeto"
            placeholder="Selecione um Projeto"
            :return-object="true"
            :disabled="payload.municipio.municipioId > 0"
            multiple
            @change="getByProjeto1(projetoId, payload.regiao.regiaoSebraeId)"
          >
            <template slot="no-data"> Nenhum Projeto retornado </template>
          </v-select>
        </template>
      </v-col>

      <v-col>
        <app-municipio-combobox
          ref="municipioSelect"
          :filter="payload"
          :disabled="
            payload.indicador.perguntaId > 0 ||
            payload.regiao.regiaoSebraeId > 0
          "
          @changeMunicipio="getByMunicipio(payload.municipio.codigoIBGE)"
        />
      </v-col>
    </v-row>

    <v-row>
      <!--
      <v-col cols="3">
       <app-perguntas-master-combobox
          :filter="payload"
          :disabled="payload.municipio.municipioId > 0"
          @changeIndicador="getByIndicadores(payload.indicador.perguntaId, projetoId, payload.regiao.regiaoSebraeId)"
          ref="indicadorSelect"
        >
        </app-perguntas-master-combobox>
      </v-col>

        <v-col>
          <v-select
            id="select"
            v-model ="filtro.indicadorGrupoId"
            item-text ="nome"
            item-name ="indicadorGrupoId"
            item-value ="indicadorGrupoId"
            :items="grupoIndicador"
            label ="Grupo de Indicadores"
            placeholder ="Grupo de Indicadores"
            @change="loadSubGrupos()"
          >
            <template slot="no-data">
              Nada retornado
            </template>
          </v-select>
        </v-col>
        -->
      <v-col cols="3">
        <v-select
          id="select_subgrupo"
          v-model="filtro.indicadorSubgrupoId"
          name="select_subgrupo"
          item-text="nome"
          item-name="indicadorSubgrupoId"
          item-value="indicadorSubgrupoId"
          :items="subGrupoIndicador"
          label="Grupo"
          placeholder="Grupo"
          clearable
          @change="loadIndicadores()"
        >
          <template slot="no-data"> Nada retornado </template>
        </v-select>
      </v-col>
      <!--
        <v-col>
          <v-select
            id="select"
            v-model ="filtro.indicadorId"
            item-text ="nome"
            item-name ="indicadorId"
            item-value ="indicadorId"
            :items="indicador"
            :disabled="!subGrupoSelecionado"
            label ="Indicador"
            placeholder ="Indicador"
            v-on:change ="$emit('onChange')"
            ref="solutionindicador"
          >
            <template slot="no-data">
              Nada retornado
            </template>
          </v-select>
        </v-col>
        -->
      <v-col v-if="$store.state.uf === 'BA'" cols="3">
        <app-municipio-combobox
          ref="municipioSelect"
          :filter="payload"
          :disabled="
            payload.indicador.perguntaId > 0 ||
            payload.regiao.regiaoSebraeId > 0
          "
          @changeMunicipio="getByMunicipio(payload.municipio.codigoIBGE)"
        />
      </v-col>
      <v-col v-if="$store.state.uf === 'BA'" cols="3">
        <template>
          <v-select
            id="projeto-select"
            v-model="projetoId"
            :filter="payload"
            item-text="nome"
            item-name="projetoId"
            :items="projetos"
            label="Projeto"
            placeholder="Selecione um Projeto"
            :return-object="true"
            :disabled="payload.municipio.municipioId > 0"
            multiple
            @change="getByProjeto1(projetoId, payload.regiao.regiaoSebraeId)"
          >
            <template slot="no-data"> Nenhum Projeto retornado </template>
          </v-select>
        </template>
      </v-col>
      <v-col cols="3" class="text-right">
        <v-btn color="accent" @click="limpar"> Limpar </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <table width="100%">
          <tr
            v-if="mapaRegional.length"
            style="background-color: #0489b1"
            align="center"
          >
            <td>
              <b>{{ mapaRegional.length }}</b> município(s) na Unidade Regional
              <b>{{ payload.regiao.nome }}</b>
            </td>
          </tr>
          <tr
            v-if="projetoId[0]"
            style="background-color: #5f9ea0"
            align="center"
          >
            <td>
              <b>{{ mapaProjeto.length }}</b> município(s) no(s) projeto(s)
              <span v-for="projeto in projetoId" :key="projeto.projetoId">
                <b>| {{ projeto.nome }}</b>
              </span>
            </td>
          </tr>
          <tr
            v-if="payload.indicador.perguntaId"
            style="background-color: #04b486"
            align="center"
          >
            <td>
              <b>{{ mapaIndicadores.length }}</b> município(s) com resposta
              <b>SIM</b> para: <b>{{ payload.indicador.indicadorTexto }}</b>
            </td>
          </tr>
        </table>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div _ngcontent-c21="">
          <div _ngcontent-c21="" class="mapa">
            <div _ngcontent-c21="">
              <svg
                _ngcontent-c21=""
                xmlns:xlink="http://www.w3.org/1999/xlink"
                preserveAspectRatio="xMidYMid meet"
                xmlns="http://www.w3.org/2000/svg"
                :viewBox="$store.state.svg"
                @mouseout="tooltipMap.disable = 'none'"
              >
                <!-- MAPA FIXO -->
                <g _ngcontent-c21="" class="uf" transform="scale(1 -1)">
                  <g
                    v-for="municipio in mapaFixo"
                    :key="municipio.municipioId"
                    _ngcontent-c21=""
                    vector-effect="non-scaling-stroke"
                    @mousemove="exibirMunicipiosNomes($event, municipio)"
                  >
                    <g
                      v-if="
                        mapaRegional.length === 0 &&
                        mapaProjeto.length === 0 &&
                        mapaIndicadores.length === 0
                      "
                      _ngcontent-c21=""
                      class="faixa1"
                      :codigo="municipio.codigoIBGE"
                      :nome="municipio.nome"
                      link=""
                    >
                      <g
                        @click="exibeDadosMunicipio(municipio)"
                        v-html="municipio.poligono"
                      />
                    </g>
                    <g
                      v-else
                      _ngcontent-c21=""
                      class="faixa1"
                      :codigo="municipio.codigoIBGE"
                      :nome="municipio.nome"
                      link=""
                    >
                      <g v-html="municipio.poligono" />
                    </g>
                  </g>
                </g>

                <!-- MAPA MUNICÍPIO -->
                <g _ngcontent-c21="" class="uf" transform="scale(1 -1)">
                  <g
                    v-for="municipio in mapaMunicipio"
                    :key="municipio.municipioId"
                    _ngcontent-c21=""
                    vector-effect="non-scaling-stroke"
                    @mousemove="exibirMunicipiosNomes($event, municipio)"
                  >
                    <g
                      _ngcontent-c21=""
                      class="faixa2"
                      :codigo="municipio.codigoIBGE"
                      :nome="municipio.nome"
                      link=""
                    >
                      <g
                        @click="exibeDadosMunicipio(municipio)"
                        v-html="municipio.poligono"
                      />
                    </g>
                  </g>
                </g>

                <!-- MAPA VARIÁVEL REGIÃO-->
                <g
                  v-if="payload.regiao.regiaoSebraeId"
                  _ngcontent-c21=""
                  class="uf"
                  transform="scale(1 -1)"
                >
                  <g
                    v-for="municipio in mapaRegional"
                    :key="municipio.municipioId"
                    _ngcontent-c21=""
                    vector-effect="non-scaling-stroke"
                    @mousemove="exibirMunicipiosNomes($event, municipio)"
                  >
                    <g
                      _ngcontent-c21=""
                      class="faixa3"
                      :codigo="municipio.codigoIBGE"
                      :nome="municipio.nome"
                      link=""
                    >
                      <g
                        @click="exibeDadosMunicipio(municipio)"
                        v-html="municipio.poligono"
                      />
                    </g>
                  </g>
                </g>

                <!-- MAPA VARIÁVEL PROJETO-->
                <g
                  v-if="mapaProjeto.length != 0"
                  _ngcontent-c21=""
                  class="uf"
                  transform="scale(1 -1)"
                >
                  <g
                    v-for="municipio in mapaProjeto"
                    :key="municipio.municipioId"
                    _ngcontent-c21=""
                    vector-effect="non-scaling-stroke"
                    @mousemove="exibirMunicipiosNomes($event, municipio)"
                  >
                    <g
                      _ngcontent-c21=""
                      class="faixa2"
                      :codigo="municipio.codigoIBGE"
                      :nome="municipio.nome"
                      link=""
                    >
                      <g
                        @click="exibeDadosMunicipio(municipio)"
                        v-html="municipio.poligono"
                      />
                    </g>
                  </g>
                </g>

                <!-- MAPA DOS INDICADORES -->
                <g
                  v-if="payload.indicador.indicadorResposta"
                  _ngcontent-c21=""
                  class="uf"
                  transform="scale(1 -1)"
                >
                  <g
                    v-for="municipio in mapaIndicadores"
                    :key="municipio.municipioId"
                    _ngcontent-c21=""
                    vector-effect="non-scaling-stroke"
                    @mousemove="exibirMunicipiosNomes($event, municipio)"
                  >
                    <g
                      _ngcontent-c21=""
                      class="faixa4"
                      :codigo="municipio.codigoIBGE"
                      :nome="municipio.nome"
                      link=""
                    >
                      <g
                        @click="exibeDadosMunicipio(municipio)"
                        v-html="municipio.poligono"
                      />
                    </g>
                  </g>
                </g>
              </svg>
              <div
                class="description"
                :style="{
                  top: tooltipMap.pageY,
                  left: tooltipMap.pageX,
                  display: tooltipMap.disable,
                  position: 'absolute',
                }"
              >
                {{ municipioName }}
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <AppModalIndicadorPrimario
      :exibe-modal="exibeModal"
      @closeModal="exibeModal = false"
      @fecharModal="fecharModal"
      :municipio="municipioSelecionado"
    >
    </AppModalIndicadorPrimario>
    <!-- <app-modal-indicador-game
      :municipio="municipioSelecionado"
      :exibe-modal="exibeModal"
      :itens="[{ titulo: 'Dados Gerais', texto: '1' }]"
      @fecharModal="fecharModal"
      @closeModal="exibeModal = false"
    /> -->

    <v-row v-if="mapaIndicadores.length > 0" class="text-center">
      <v-col class="text-center" cols="6" align="center">
        <v-data-table
          :headers="headers"
          :items="mapaIndicadores"
          class="elevation-1"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:item.Municipio="{ item }">
            <span>
              {{ item.nome }}
            </span>
          </template>
          <template v-slot:item.acao="{ item }">
            <span>
              <a @click="exibeDadosMunicipio(item)">Detalhes</a>
            </span>
          </template>
          <template slot="no-data">
            <span> Selecione um filtro acima </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row
      v-if="indicadoresMunicipios.length > 0"
      class="text-center"
      align="center"
    >
      <v-col class="text-center" cols="12" align="center">
        <v-data-table
          :headers="headers2"
          :items="indicadoresMunicipios"
          class="table-striped elevation-1"
        >
          <template v-slot:items="props">
            <td class="text-xs-right">
              {{ props.item.indicadores }}
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!--
    <v-row class="text-center" v-if="indicadoresMunicipios.length > 0" align="center">
      <v-col class="text-center" cols="6" align="center">
        <v-simple-table
          dense
          height="300px"
          align="center"
        >
          <template v-slot:default>
            <thead>
              <th>Município</th>
              <th v-for="titulo in indicadoresBySubGrupo" v-bind:key="titulo.nome">{{titulo.nome}}</th>
            </thead>
            <tbody>
              <tr v-for="dados in indicadoresMunicipios" v-bind:key="dados.municipio">
                <td>{{dados.municipio}}</td>
                <td v-for="indicadores in dados.indicadores" v-bind:key="indicadores">{{indicadores}}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AppModalIndicadorPrimario from "./ModalIndicadorPrimario";
// import AppModalIndicadorGame from "./ModalIndicadorGame";

import { AppMunicipioCombobox, municipioAPI } from "@/modules/municipio";
// import { AppPerguntasMasterCombobox, perguntaAPI } from '@/modules/game'
// import { AppProjetoCombobox } from '@/modules/projeto'
import { AppRegiaoCombobox } from "@/modules/regiao";
import { subGrupoIndicadorAPI } from "@/modules/indicadores/subGrupoIndicador/API/subGrupoIndicadorAPI";
import { indicadorAPI } from "@/modules/indicadores/indicador/API/indicadorAPI";
import { indicadorMunicipioAPI } from "@/modules/indicadores/API/IndicadorMunicipioAPI";
import { grupoIndicadorAPI } from "@/modules/indicadores/grupoIndicador/API/grupoIndicadorAPI";

export default {
  components: {
    // AppModalIndicadorGame,
    AppMunicipioCombobox,
    // AppPerguntasMasterCombobox,
    // AppProjetoCombobox,
    AppRegiaoCombobox,
    AppModalIndicadorPrimario,
  },
  computed: {
    ...mapGetters([
      "isLogged",
      "isAuthenticated",
      "isConsultor",
      "isAdmin",
      "loading",
      "user",
      "uf",
      "logo",
      "svg",
    ]),
  },
  data() {
    return {
      headers: [
        { text: "Município", value: "nome" },
        { text: "Detalhes", value: "acao" },
      ],
      headers2: [],
      estado: this.uf,
      indicadores: [],
      indicadoresFiltrados: 0,
      mapaFixo: [],
      mapaMunicipio: [],
      mapaRegional: [],
      mapaProjeto: [],
      mapaIndicadores: [],
      regionalId: null,
      projetoId: [],
      municipios: [],
      projetos: [],
      municipiosCE: [],
      municipiosCE_filtrados: [],
      municipiosRegiao: [],
      municipiosProjeto: [],
      municipioSelecionado: {},
      municipiosFiltrados: 0,
      codigos: "",
      payload: {
        indicador: {},
        municipio: {},
        municipiosFiltrados: [],
        projeto: [],
        regiao: {},
        municipiosFiltradosIndicadores: [],
      },
      exibeModal: false,
      svgUF: this.svg,
      projetosFiltrados: 0,
      perguntasFiltradas: 0,
      filtro: {
        indicadorGrupoId: 0,
        indicadorSubgrupoId: 0,
        indicadorId: 0,
        grupoIndicador: [],
        subGrupoIndicador: [],
        Indicador: [],
      },
      subGrupoIndicador: [],
      grupoIndicador: [],
      indicador: [],
      indicadoresMunicipios: [],
      indicadoresBySubGrupo: [],
      subGrupoSelecionado: false,
      tooltipMap: {
        pageX: 10 + "px",
        pageY: 10 + "px",
        disable: "none",
      },
      municipioName: "",
    };
  },
  methods: {
    ...mapActions([
      "setLoading",
      "setToken",
      "setUser",
      "setAdmin",
      "setExpired",
      "setUf",
      "setLogo",
      "setSvg",
    ]),
    exibirMunicipiosNomes(e, municipio) {
      this.municipioName = municipio.nome;
      this.tooltipMap = {
        pageX: e.pageX + 10 + "px",
        pageY: e.pageY - 150 + "px",
        disable: "block",
      };
    },
    loadIndicadores() {
      indicadorAPI
        .list()
        .then((resp) => {
          this.indicador = resp.data.filter((i) => {
            return i.indicadorSubgrupoId === this.filtro.indicadorSubgrupoId;
          });
          this.filtro.indicador = resp.data;
          this.subGrupoSelecionado = true;
        })
        .catch(() => {
          this.$notification.error("Erro ao listar");
        });
      var codigosIBGE = "0";
      if (this.mapaMunicipio.length > 0) {
        for (var iii = 0; iii < this.mapaMunicipio.length; iii++) {
          codigosIBGE = codigosIBGE + "," + this.mapaMunicipio[iii].codigoIBGE;
        }
      } else if (this.mapaProjeto.length > 0) {
        for (var i = 0; i < this.mapaProjeto.length; i++) {
          codigosIBGE = codigosIBGE + "," + this.mapaProjeto[i].codigoIBGE;
        }
      } else if (this.mapaRegional.length > 0) {
        for (var ii = 0; ii < this.mapaRegional.length; ii++) {
          codigosIBGE = codigosIBGE + "," + this.mapaRegional[ii].codigoIBGE;
        }
      }
      indicadorMunicipioAPI
        .getindicadoresbysubgrupo(this.filtro.indicadorSubgrupoId)
        .then((resp) => {
          this.indicadoresBySubGrupo = resp.data;
          this.headers2 = [];
          this.headers2.push({
            text: "Município",
            value: "municipio",
            align: "start",
            width: "18%",
          });
          for (var i = 0; i < this.indicadoresBySubGrupo.length; i++) {
            this.headers2.push({
              text: this.indicadoresBySubGrupo[i].nome,
              value: `indicadores[${i}]`,
              align: "center",
            });
          }
        })
        .catch(() => {
          this.$notification.error("Erro ao listar");
        });
      indicadorMunicipioAPI
        .getMontaIndicador(this.filtro.indicadorSubgrupoId, codigosIBGE)
        .then((resp) => {
          this.indicadoresMunicipios = resp.data;
        })
        .catch(() => {
          this.$notification.error("Erro ao listar");
        });
    },
    /*
    loadSubGrupos () {
      subGrupoIndicadorAPI.list()
        .then(resp => {
          this.subGrupoIndicador = resp.data.filter((i) => {
            return i.indicadorGrupoId === this.filtro.indicadorGrupoId
          })
          this.filtro.subGrupoIndicador = resp.data
          this.subGrupoSelecionado = false
        })
        .catch(resp => {
          this.$notification.error('Erro ao listar')
        })
    },
    */
    loadSubGrupos() {
      subGrupoIndicadorAPI
        .list()
        .then((resp) => {
          this.subGrupoIndicador = resp.data;
          this.filtro.subGrupoIndicador = resp.data;
          this.subGrupoSelecionado = false;
        })
        .catch(() => {
          this.$notification.error("Erro ao listar");
        });
    },
    loadGrupos() {
      grupoIndicadorAPI
        .list()
        .then((resp) => {
          this.grupoIndicador = resp.data;
          this.filtro.grupoIndicador = resp.data;
          this.subGrupoSelecionado = false;
        })
        .catch(() => {
          this.$notification.error("Erro ao listar");
        });
    },
    exibeDadosMunicipio(municipio) {
      this.municipioSelecionado = municipio;
      this.exibeModal = true;
    },
    fecharModal() {
      this.exibeModal = false;
    },
    changeRegiao() {
      this.setLoading(true);
      // this.$refs.municipioSelect.filtrar()
      if (this.payload.regiao.regiaoSebraeId) {
        municipioAPI
          .getByRegiao(this.payload.regiao.regiaoSebraeId)
          .then((resp) => {
            this.municipiosRegiao = resp.data;
            this.$forceUpdate();
            if (this.payload.indicador.perguntaId) {
              this.changeIndicador();
            }
            this.setLoading(false);
          })
          .catch(() => {
            this.$notification.error("Erro ao listar regiões");
            this.setLoading(false);
          });
      } else {
        this.$refs.municipioSelect.filtrar();
        this.setLoading(false);
      }
    },
    async changeProjeto() {
      /*
      this.setLoading(true)
      this.municipiosCE_filtrados = this.municipiosCE.filter(municipio => {
        return municipio.projetoId === this.payload.projeto.projetoId
      })
      this.setLoading(false)
      */
      this.setLoading(true);
      // this.$refs.municipioSelect.filtrar()
      if (this.payload.projeto.projetoId) {
        /*
        municipioAPI.getByProjeto(this.payload.projeto.projetoId)
          .then(resp => {
            this.municipiosProjeto = resp.data
            this.$forceUpdate()
            if (this.payload.indicador.perguntaId) {
              this.changeIndicador()
            }
            if (this.payload.regiao.regiaoSebraeIdId) {
              this.changeRegiao()
            }
            console.log(this.municipiosProjeto)
            for (var i = 0; i < this.municipiosProjeto.length; i++) {
              if (this.codigos === '') {
                this.codigos = this.municipiosProjeto[i].codigoIBGE
              } else {
                this.codigos = this.codigos + ',' + this.municipiosProjeto[i].codigoIBGE
              }
            }
            municipioAPI.getProjetos(this.codigos)
              .then(resp => {
                this.municipiosCE_filtrados = resp.data
                this.$forceUpdate()
                this.setLoading(false)
              })
              .catch(resp => {
                this.$notification.error('Erro ao filtrar Municípios')
                this.setLoading(false)
              })
            this.setLoading(false)
          })
          .catch(resp => {
            this.$notification.error('Erro ao listar projetos')
            this.setLoading(false)
          })
          */
        this.municipiosCE_filtrados = this.municipiosCE.filter((municipio) => {
          return municipio.projetoId === this.payload.projeto.projetoId;
        });
        this.codigos = "";
        for (var i = 0; i < this.municipiosCE_filtrados.length; i++) {
          if (this.codigos === "") {
            this.codigos = this.municipiosCE_filtrados[i].codigoIBGE;
          } else {
            this.codigos =
              this.codigos + "," + this.municipiosCE_filtrados[i].codigoIBGE;
          }
        }
        municipioAPI
          .getProjetos(this.codigos)
          .then((resp) => {
            this.municipiosProjeto = resp.data;
            this.$forceUpdate();
            this.setLoading(false);
          })
          .catch(() => {
            this.$notification.error("Erro ao filtrar Municípios");
            this.setLoading(false);
          });
      } else {
        this.$refs.municipioSelect.filtrar();
        this.setLoading(false);
      }
    },
    listarMunicipios() {
      municipioAPI
        .list(this.$store.state.uf)
        .then((resp) => {
          this.municipios = resp.data;
          this.$forceUpdate();
        })
        .catch(() => {
          this.$notification.error("Erro ao listar municípios");
        });
    },
    getMunicipiosCE() {
      municipioAPI
        .getMunicipiosCE(this.$store.state.uf)
        .then((resp) => {
          this.municipiosCE = resp.data;
          for (var i = 0; i < this.municipiosCE.length; i++) {
            this.projetos.push({
              projetoId: this.municipiosCE[i].projeto.projetoId,
              nome: this.municipiosCE[i].projeto.nome,
            });
          }
          this.$forceUpdate();
        })
        .catch(() => {
          this.$notification.error("Erro ao listar municípios");
        });
    },
    limpar() {
      this.payload.municipio = {};
      this.payload.projeto = {};
      this.payload.regiao = {};
      this.payload.indicador = {};
      this.payload.municipiosFiltradosIndicadores = [];
      this.payload.municipiosFiltrados = [];
      this.municipiosRegiao = [];
      this.projetoId = [];
      this.mapaProjeto = [];
      this.mapaIndicadores = [];
      this.mapaRegional = [];
      this.mapaMunicipio = [];
      this.filtro = {
        indicadorGrupoId: 0,
        indicadorSubgrupoId: 0,
        indicadorId: 0,
        subGrupoIndicador: [],
        Indicador: [],
      };
      this.subGrupoIndicador = [];
      this.indicador = [];
      this.indicadoresBySubGrupo = [];
      this.indicadoresMunicipios = [];
      this.subGrupoSelecionado = false;
      this.loadGrupos();
      this.loadSubGrupos();
      this.$refs.municipioSelect.filtrar();
    },
    /*
    changeIndicador () {
      this.setLoading(true)
      perguntaAPI.getMunicipiosComSim(this.payload.indicador.perguntaId)
        .then(resp => {
          this.payload.municipiosFiltradosIndicadores = resp.data
          this.$refs.municipioSelect.filtrar()
          this.setLoading(false)
        })
        .catch(resp => {
          this.$notification.error('Erro ao listar municípios')
          this.setLoading(false)
        })
    },
    */
    getMapaFixo() {
      this.setLoading(true);
      municipioAPI
        .getMapaFixo()
        .then((resp) => {
          this.mapaFixo = resp.data;
          this.setLoading(false);
        })
        .catch(() => {
          this.$notification.error("Erro ao carregar");
          this.setLoading(false);
        });
    },
    getByMunicipio(codigoIBGE) {
      this.mapaMunicipio = this.mapaFixo.filter((m) => {
        return m.codigoIBGE === codigoIBGE;
      });
    },
    getByRegional(id) {
      this.indicadoresMunicipios = [];
      this.filtro.indicadorSubgrupoId = 0;
      this.setLoading(true);
      this.payload.indicador = {};
      this.mapaIndicadores = [];
      municipioAPI
        .getByRegional(id)
        .then((resp) => {
          this.mapaRegional = resp.data;
          this.setLoading(false);
        })
        .catch(() => {
          this.$notification.error("Erro ao carregar");
          this.setLoading(false);
        });
      if (this.projetoId.length > 0) {
        this.getByProjeto1(this.projetoId, id);
      }
    },
    getByProjeto1(projetoIds, regionalId) {
      this.setLoading(true);
      this.payload.indicador = {};
      this.mapaIndicadores = [];
      if (projetoIds.length > 0) {
        var projetoIdsString = "";
        for (var i = 0; i < projetoIds.length; i++) {
          if (projetoIdsString === "") {
            projetoIdsString = projetoIds[i].projetoId;
          } else {
            projetoIdsString = projetoIdsString + "," + projetoIds[i].projetoId;
          }
        }
        if (regionalId === undefined) {
          regionalId = null;
        }
        municipioAPI
          .getByProjeto1(projetoIdsString, regionalId)
          .then((resp) => {
            this.mapaProjeto = resp.data;
            this.setLoading(false);
          })
          .catch(() => {
            this.$notification.error("Erro ao carregar");
            this.setLoading(false);
          });
      } else {
        this.mapaProjeto = [];
        this.setLoading(false);
      }
    },
    getByIndicadores(perguntaId, projetoIds, regionalId) {
      this.setLoading(true);
      if (projetoIds.length > 0) {
        var projetoIdsString = "";
        for (var i = 0; i < projetoIds.length; i++) {
          if (projetoIdsString === "") {
            projetoIdsString = projetoIds[i].projetoId;
          } else {
            projetoIdsString = projetoIdsString + "," + projetoIds[i].projetoId;
          }
        }
      } else {
        projetoIdsString = null;
      }
      // if (projetoIds[0]) { projetoIds = this.projetoId.projetoId }
      if (regionalId === undefined) {
        regionalId = null;
      }
      municipioAPI
        .getByIndicadores(perguntaId, projetoIdsString, regionalId)
        .then((resp) => {
          this.mapaIndicadores = resp.data;
          this.setLoading(false);
        })
        .catch(() => {
          this.$notification.error("Erro ao carregar");
          this.setLoading(false);
        });
      this.changeIndicador();
    },
  },
  mounted() {
    this.getMapaFixo();
    this.listarMunicipios();
    this.getMunicipiosCE();
    this.loadGrupos();
    this.loadSubGrupos();
    // console.log('Store SVG' + this.$store.state.svg)
    // console.log('SVG' + this.svg)
  },
};
</script>

<style lang="scss">
.description {
  pointer-events: none;
  position: absolute;
  font-size: 18px;
  text-align: center;
  background: white;
  padding: 10px 15px;
  z-index: 5;
  line-height: 30px;
  margin: 0 auto;
  color: #21669e;
  border-radius: 5px;
  box-shadow: 0 0 0 1px #eee;
  transform: translateX(-50%);

  &.active {
    display: block;
  }
  &:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 100%;
    width: 0;
    height: 0;
    margin-left: -10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
  }
}
svg[_ngcontent-c21],
svg[_ngcontent-c22] {
  -ms-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}

.target {
  fill: none;
  pointer-events: visibleFill;
}
svg:hover g:not(:hover) text {
  opacity: 0;
}

.container[_ngcontent-c21],
.container[_ngcontent-c22] {
  overflow: hidden;
}

.mapa[_ngcontent-c21],
.mapa[_ngcontent-c22] {
  width: 100%;
  margin-top: 0px;
}

.alturafixa_legenda[_ngcontent-c21],
.alturafixa_legenda[_ngcontent-c22] {
  height: 50px;
  margin-top: 0px;
}

.ie10[_ngcontent-c21] .mapa[_ngcontent-c21] svg[_ngcontent-c21],
.ie10[_ngcontent-c22] .mapa[_ngcontent-c22] svg[_ngcontent-c22] {
  height: 600px;
}

@media all and (-ms-high-contrast: none) {
  .mapa[_ngcontent-c21] svg[_ngcontent-c21],
  .mapa[_ngcontent-c22] svg[_ngcontent-c22] {
    height: 600px;
  }
}

.mapa__label--mun-selecionado[_ngcontent-c21],
.mapa__label--mun-selecionado[_ngcontent-c22] {
  width: 100%;
  padding-bottom: 3px;
}

.mapa__label--mun-selecionado[_ngcontent-c21] .tip[_ngcontent-c21],
.mapa__label--mun-selecionado[_ngcontent-c22] .tip[_ngcontent-c22] {
  font-size: 0.7em;
  padding: 0;
}

#faixas[_ngcontent-c21],
#faixas[_ngcontent-c22] {
  padding: 0 0 7px 5%;
}
#faixas[_ngcontent-c21] h4[_ngcontent-c21],
#faixas[_ngcontent-c22] h4[_ngcontent-c22] {
  font-size: 0.85em;
  color: #fff;
  margin: 0 0 5px 0;
  padding: 5px;
  text-align: center;
  background-color: #888;
}
.txt-faixa[_ngcontent-c21],
.txt-faixa[_ngcontent-c22] {
  font-size: 0.82em;
  line-height: 23px;
  margin-left: 5px;
  vertical-align: bottom;
}
.ico-faixa[_ngcontent-c21],
.ico-faixa[_ngcontent-c22] {
  display: inline-block;
  border: 1px solid #aaa;
  width: 20px;
  height: 12px;
  margin-left: 10px;
}
.seletor-periodos[_ngcontent-c21],
.seletor-periodos[_ngcontent-c22] {
  text-align: center;
}
.periodo-link[_ngcontent-c21]:after,
.periodo-link[_ngcontent-c22]:after {
  content: " /";
}

g.faixa1[_ngcontent-c21] polygon[_ngcontent-c21] {
  fill: #81bef7;
}
g.faixa2[_ngcontent-c21] polygon[_ngcontent-c21] {
  fill: #5f9ea0;
}
g.faixa4[_ngcontent-c21] polygon[_ngcontent-c21] {
  fill: #04b486;
}
g.faixa6[_ngcontent-c21] polygon[_ngcontent-c21] {
  fill: #04b486;
}

g.faixa1[_ngcontent-c22] polygon[_ngcontent-c22] {
  fill: #81bef7;
}
g.faixa2[_ngcontent-c22] polygon[_ngcontent-c22] {
  fill: #5f9ea0;
}
g.faixa4[_ngcontent-c22] polygon[_ngcontent-c22] {
  fill: #04b486;
}
g.faixa6[_ngcontent-c22] polygon[_ngcontent-c22] {
  fill: #04b486;
}

polygon[_ngcontent-c21],
polygon[_ngcontent-c22] {
  stroke: #fff;
  stroke-width: 0.01px;
  cursor: default;
  -webkit-transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  transition: all 700ms ease;
}
g.checked[_ngcontent-c21] polygon[_ngcontent-c21],
g.checked[_ngcontent-c22] polygon[_ngcontent-c22] {
  stroke: #fff;
  stroke-width: 0.01px;
  fill: #0d4768;
}
polygon[_ngcontent-c21]:hover,
polygon[_ngcontent-c22]:hover {
  fill-opacity: 0.5;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

g[_ngcontent-c21],
.periodo-link[_ngcontent-c21] {
  cursor: pointer;
}
g[_ngcontent-c22],
.periodo-link[_ngcontent-c22] {
  cursor: pointer;
}
</style>
