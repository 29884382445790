<template>
  <v-select
    id="contrato-select"
    v-model="filter.contrato"
    item-text="statusContratoNome"
    item-value="statusContratoId"
    :items="contratos"
    label="Contrato"
    placeholder="Selecione um Contrato"
    :return-object="true"
    :disabled="disabled"
    multiple
    @change="emitValue"
  >
  <div
    style="display: flex;padding: 0 16px;cursor: pointer;"
    slot="prepend-item"
    ripple
    @click="toggle"
  >
    <div style="margin-right: 32px;">
      <v-icon :color="filter.contrato.length > 0 ? 'indigo darken-4' : ''" >{{ icon }}</v-icon>
    </div>
    <div>Todos</div>
  </div>
  <v-divider
    slot="prepend-item"
    class="mt-2"
  />
    <template slot="no-data">
      Nenhum Contrato retornado
    </template>
  </v-select>
</template>

<script>
import { municipioAPI } from '@/modules/municipio'

export default {
  name: 'AppContratoCombobox',
  props: {
    filter: { type: Object, required: true },
    disabled: { type: Boolean, required: false, default: false }
  },
  data () {
    return {
      contratos: []
    }
  },
  computed: {
  likesAll () {
    return this.filter.contrato.length === this.contratos.length
  },
  likesSome () {
    return this.filter.contrato.length > 0 && !this.contratos
  },
  icon () {
    if (this.likesAll) return 'mdi-close-box'
    if (this.likesSome) return 'mdi-minus-box'
    return 'mdi-checkbox-blank-outline'
  }
},
  mounted () {
    this.list()
  },
  methods: {
    emitValue (e) {
      this.$emit('changeContrato', e)
    },
     toggle () {
    this.$nextTick(() => {
      if (this.likesAll) {
        this.filter.contrato = []
        this.$emit('changeContrato', this.filter.contrato)
      } else {
       this.filter.contrato = this.contratos.slice()
        this.$emit('changeContrato', this.filter.contrato)
      }
    })
  },
    list () {
      municipioAPI.getContrato().then((resp) => {
        this.contratos = resp.data
      }).catch(() => {
        this.$notification.error('Erro ao listar Contratos')
      })
    }
  }
}
</script>
