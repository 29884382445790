import baseAPI from '@/http'

const router = 'api/ClienteOculto'
const routerSeloGrupo = 'api/SeloClienteOcultoGrupos'

export const clienteOcultoAPI = {

    gerarClienteOcultoConsolidadoSituacao: (filtro) => baseAPI.post(`${router}` + `/get-cliente-oculto-consolidado-situacao`, filtro),
    gerarXlsClienteOcultoConsolidadoSituacao: (filtro) => baseAPI.post(`${router}` + `/get-xlsx-cliente-oculto-consolidado-situacao`, filtro, { responseType: 'blob' }),
    gerarExecucaoAnaliticaClienteOculto: (filtro) => baseAPI.post(`${router}` + `/get-execucao-analitica-cliente-oculto`, filtro),
    gerarXlsExecucaoAnaliticaClienteOculto: (filtro) => baseAPI.post(`${router}` + `/get-xlsx-execucao-analitica-cliente-oculto`, filtro, { responseType: 'blob' }),
    gerarSituacaoDetalhadaEnvioEvidencia: (filtro) => baseAPI.post(`${router}` + `/get-situacao-detalhada-envio-evidencia`, filtro),
    gerarXlsSituacaoDetalhadaEnvioEvidencia: (filtro) => baseAPI.post(`${router}` + `/get-xlsx-situacao-detalhada-envio-evidencia`, filtro, { responseType: 'blob' }),

}
export const seloClienteOcultoGruposAPI = {
    list: () => baseAPI.get(`${routerSeloGrupo}`),
}