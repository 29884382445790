<template>
  <v-dialog
    v-model="exibeModal"
    scrollable
    persistent
  >
    <v-card>
      <v-card-title
        class="modal-header elevation-10"
      >
        <v-col>
          <slot name="title">
            <span style="font-size: 30px"><b>{{ municipio.municipioNome }}</b></span>
          </slot>
        </v-col>
        <v-col class="text-right">
          <v-icon
            color="white"
            @click="$emit('closeModal')"
          >
            mdi-window-close
          </v-icon>
        </v-col>
      </v-card-title>
      <div v-if="municipioLocal.projeto">
        <v-card-text>
          <v-row>
            <v-col>
              <span>
                <b>Região: </b> {{ municipio.nomeRegiao }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-tabs
                v-model="tab"
                background-color="#5F9EA0"
                dark
                centered
              >
                <v-tab
                  v-for="item in itens"
                  :key="item.titulo"
                  center
                >
                  {{ item.titulo }}
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item
                  v-for="item in itens"
                  :key="item.titulo"
                >
                  <!-- DADOS GERAIS  -->
                  <v-row
                    v-if="item.titulo === 'Dados Gerais'"
                    class="rowDadosGerais"
                  >
                    <v-col class="primary">
                      <b>Questões respondidas SIM: </b>
                      {{ Math.round((municipioLocal.questoesSim / (municipioLocal.questoesSim + municipioLocal.questoesNao)) * 100) }}%
                    </v-col>

                    <v-col class="primary">
                      <b>Questões Evidenciadas:</b>
                      {{ Math.round((municipioLocal.questoesEvidenciadas / (municipioLocal.questoesSim + municipioLocal.questoesNao)) * 100) }}%
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="item.titulo === 'Dados Gerais'"
                    class="rowDadosGeraisValores"
                  >
                    <v-col class="accent">
                      {{ municipioLocal.questoesSim }}
                    </v-col>

                    <v-col class="accent">
                      {{ municipioLocal.questoesEvidenciadas }}
                    </v-col>
                  </v-row>

                  <!-- FINAL DOS DADOS GERAIS  -->

                  <!-- ENCONTROS -->
                  <v-row
                    v-if="item.titulo === 'Dados Gerais'"
                  >
                    <v-col>
                      <v-data-table
                        :headers="cabecalhoEncontros"
                        :items="municipioLocal.relatoriosUsuarios"
                        :disable-pagination="true"
                        :hide-default-footer="true"
                      >
                        <template slot="no-data">
                          <span>
                            Nenhum encontro retornado
                          </span>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>

                  <!-- TEMAS PONTOS
                  <v-row
                    v-if="item.titulo === 'Dados Gerais'"
                  >
                    <v-col>
                      <v-data-table
                        :headers="cabecalhoTemasPontos"
                        :items="municipioLocal.temas"
                        :disable-pagination="true"
                        :hide-default-footer="true"
                      >
                        <template v-slot:item.percentualAtingido="{ item }">
                          <span>{{ Math.round((item.pontos / item.tema.pontos) * 100) }}%</span>
                        </template>

                        <template slot="no-data">
                          <span>
                            Nenhum tema retornado
                          </span>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                   FIM DOS TEMAS PONTOS  -->

                  <!-- PERGUNTAS  -->
                  <!-- SOMENTE PERGUNTAS DO GRUPO 0 -->
                  <v-row
                    v-if="item.titulo !== 'Dados Gerais'"
                  >
                    <v-col>
                      <v-data-table
                        :headers="cabecalhoPerguntasPrincipais"
                        :items="filtrarPerguntasPrincipais(item.titulo)"
                        :disable-pagination="true"
                        :hide-default-footer="true"
                      >
                        <template v-slot:item.resposta="{ item }">
                          <div
                            v-if="item.resposta === true && item.status != 'Aprovada'"
                            align="center"
                          >
                            <table style="background-color: #0489B1">
                              <tr>
                                <td align="center">
                                  <span style="color: white">Sem evidência aprovada</span>
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div
                            v-if="item.resposta === true && item.status === 'Aprovada'"
                            align="center"
                          >
                            <table style="background-color: #04B486">
                              <tr>
                                <td align="center">
                                  <span style="color: white">EVIDÊNCIA APROVADA</span>
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div
                            v-if="item.resposta === false"
                            align="center"
                          >
                            <table style="background-color: #FF6347">
                              <tr>
                                <td align="center">
                                  <span style="color: white">NÃO</span>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </template>

                        <template slot="no-data">
                          <span>
                            Nenhuma pergunta retornada
                          </span>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                  <!-- FIM PERGUNTAS  -->
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
      <div v-else>
        <v-card-text>
          MUNICÍPIO NÃO INTEGRANTE DO PROGRAMA CIDADE EMPREENDEDORA
        </v-card-text>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { municipioAPI, perguntaAPI } from '@/modules/game'
export default {
  props: {
    municipio: { type: Object, required: true },
    exibeModal: { type: Boolean, required: true }
  },
  data () {
    return {
      cabecalhoEncontros: [
        { text: 'Consultor', value: 'nome' },
        { text: 'Encontros Agendados', value: 'encontros' },
        { text: 'Relatórios Inseridos', value: 'relatorios' }
      ],
      encontros: [],
      cabecalhoTemasPontos: [
        { text: 'Tema', value: 'tema.nome' },
        { text: 'Evolução', value: 'percentualAtingido' }
      ],
      temasPontos: [],
      itens: [
        { titulo: 'Dados Gerais', texto: '1' }
      ],
      tab: 0,
      perguntasRespondidas: [],
      perguntasEvidenciadas: [],
      cabecalhoPerguntasPrincipais: [
        { text: 'Indicador', value: 'pergunta.texto' },
        { text: 'Resposta', value: 'resposta' }
      ],
      cabecalhoPerguntasDerivadas: [
        { text: 'Indicador', value: 'pergunta.texto' },
        { text: 'Resposta', value: 'pergunta.indicadorResposta' }
      ],
      perguntasPrincipais: [],
      perguntasDerivadas: [],
      municipioLocal: {
        nome: '',
        projeto: {},
        regiao: {},
        questoesSim: 0,
        questoesEvidenciadas: 0
      }
    }
  },
  watch: {
    exibeModal: function (value) {
      if (value) {
        this.getMunicipio()
      }
    }
  },
  mounted () {

  },
  methods: {
    getMunicipio () {
      municipioAPI.getPainel(this.$store.state.uf,this.municipio.codigoIBGE)
        .then(resp => {
          this.municipioLocal = resp.data
          this.itens = [{ titulo: 'Dados Gerais', texto: '1' }]
          for (let count = 0; count < resp.data.temas.length; count++) {
            this.itens.push({ titulo: this.municipioLocal.temas[count].tema.nome, texto: count + 2 })
          }
          this.getPerguntas()
        })
        .catch(() => {

        })
    },
    getPerguntas () {
      perguntaAPI.getPerguntasMunicipio(this.municipioLocal.municipioId)
        .then(resp => {
          this.perguntasPrincipais = resp.data.filter(p => p.pergunta.grupo === 0)
          this.perguntasDerivadas = resp.data.filter(p => p.pergunta.nivel === 1 && p.resposta === true)
        })
        .catch(() => {

        })
    },
    filtrarPerguntasPrincipais (tema) {
      return this.perguntasPrincipais.filter(p => {
        return p.pergunta.master === true && p.pergunta.tema.nome === tema
      })
    },
    filtrarPerguntasDerivadas (tema) {
      return this.perguntasDerivadas.filter(p => {
        return p.pergunta.tema.nome === tema && (p.pergunta.nivel === 1 || (p.pergunta.nivel > 1 && p.resposta === true))
      })
    }
  }
}
</script>

<style lang="scss">
.rowDadosGerais {
  margin-left: 0;
  margin-right: 0;
  margin-top: 20px;
  color: #fff;
}
.rowDadosGeraisValores {
  margin-left: 0;
  margin-right: 0;
  color: #fff;
}
</style>
