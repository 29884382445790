<template>
  <v-select
    id="urs-select"
    v-model="filter.ur"
    item-text="nomeRegiao"
    item-value="ur"
    :items="urs"
    label="Unidade Regional"
    placeholder="Unidade Regional"
    @change="emitValue"
    :disabled="disabled"
    :return-object="true"
    multiple
    :solo="solo"
    hide-details="auto"
  >
    <div
      style="display: flex; padding: 0 16px; cursor: pointer"
      slot="prepend-item"
      ripple
      @click="toggle"
    >
      <div style="margin-right: 32px">
        <v-icon :color="filter.ur.length > 0 ? 'indigo darken-4' : ''">{{
          icon
        }}</v-icon>
      </div>
      <div>Todos</div>
    </div>
    <v-divider slot="prepend-item" class="mt-2" />
    <template v-slot:selection="{ item, index }">
      <v-chip v-bind="item" small v-if="index < maxDisplay">
        <span class="textoTemas">{{ item.nomeRegiao }}</span>
      </v-chip>
      <span v-if="index === maxDisplay" class="grey--text caption"
        >(+{{ filter.ur.length - maxDisplay }} urs)</span
      >
    </template>
    <template slot="no-data"> Nenhuma UR </template>
  </v-select>
</template>

<script>
import { municipioAPI } from "@/modules/municipio";
import { mapActions } from "vuex";

export default {
  name: "AppUrCombobox",
  props: {
    filter: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    solo: { type: Boolean, required: false, default: false },
    uf: { type: String, default: "" },
  },
  mounted() {
    this.loadUrs();
  },
  computed: {
    likesAll() {
      return this.filter.ur.length === this.urs.length;
    },
    likesSome() {
      return this.filter.ur.length > 0 && !this.urs;
    },
    icon() {
      if (this.likesAll) return "mdi-close-box";
      if (this.likesSome) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    ...mapActions(["setLoading"]),
    toggle() {
      this.$nextTick(() => {
        if (this.likesAll) {
          this.filter.ur = [];
          this.$emit("changeUr", this.filter.ur);
        } else {
          this.filter.ur = this.urs.slice();
          this.$emit("changeUr", this.filter.ur);
        }
      });
    },
    emitValue(e) {
      this.$emit("changeUr", e);
    },
    loadUrs() {
      municipioAPI
        .listURByUfs(this.uf)
        .then((resp) => {
          this.urs = resp.data;
        })
        .catch();
    },
  },
  data() {
    return {
      urs: [],
      maxDisplay: 2,
    };
  },
};
</script>
