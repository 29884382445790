<template>
  <v-container>
    <v-row>
      <v-col>
        <v-select
          v-model="payload.regiaoId"
          item-text="nome"
          item-name="regiaoId"
          item-value="regiaoId"
          :items="regioes"
          label="Selecione uma região"
          clearable
        >
          <template slot="no-data">
            Nenhuma Região retornada
          </template>
        </v-select>
      </v-col>

      <v-col>
        <v-select
          v-model="payload.projetoId"
          item-text="nome"
          item-name="projetoId"
          item-value="projetoId"
          :items="projetos"
          label="Selecione um Projeto"
          clearable
        >
          <template slot="no-data">
            Nenhum Projeto retornado
          </template>
        </v-select>
      </v-col>

      <v-col>
        <v-select
          v-model="payload.municipioId"
          item-text="nome"
          item-name="municipioId"
          item-value="municipioId"
          :items="municipios"
          label="Selecione um Município"
          clearable
        >
          <template slot="no-data">
            Nenhum Município retornado
          </template>
        </v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-select
          v-model="payload.indicadorId"
          item-text="nome"
          item-name="indicadorId"
          item-value="indicadorId"
          :items="indicadores"
          label="Selecione um Indicador"
          clearable
        >
          <template slot="no-data">
            Nenhum Indicador retornado
          </template>
        </v-select>
      </v-col>

      <v-col>
        <v-select
          v-model="payload.indicadorManualId"
          item-text="nome"
          item-name="indicadorManualId"
          item-value="indicadorManualId"
          :items="indicadoresManuais"
          label="Selecione um Indicador Manual"
          clearable
        >
          <template slot="no-data">
            Nenhum Indicador retornado
          </template>
        </v-select>
      </v-col>

      <v-col class="text-right">
        <v-btn
          color="accent"
        >
          Limpar
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-show="false">
      <v-col>
        <v-btn
          color="accent"
        >
          Editar
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          color="accent"
        >
          Imprimir
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="payload.grupo.nome">
      <v-col>
        <p>
          Listando os indicadores do grupo <b>{{ payload.grupo.nome }}</b>, subgrupo <b>{{ payload.subgrupo.nome }}</b>
        </p>

        <p v-if="payload.regiao">
          {{ payload.regiao.nome }}
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div _ngcontent-c21="">
          <div
            _ngcontent-c21=""
            class="mapa"
          >
            <div _ngcontent-c21="">
              <svg
                _ngcontent-c21=""
                xmlns:xlink="http://www.w3.org/1999/xlink"
                preserveAspectRatio="xMidYMid meet"
                xmlns="http://www.w3.org/2000/svg"
                :viewBox="svgUF"
                @mouseout="tooltipMap.disable = 'none'"
              >
                <!-- MAPA FIXO -->
                <g
                  _ngcontent-c21=""
                  class="uf"
                  transform="scale(1 -1)"
                >
                  <g
                    v-for="(municipio) in municipios"
                    :key="municipio.municipioId"
                    _ngcontent-c21=""
                    vector-effect="non-scaling-stroke"
                    @mousemove="exibirMunicipiosNomes($event, municipio)"
                  >
                    <g
                      v-if="regionaisSebraeFiltrados == 0 && projetosFiltrados == 0 && municipiosFiltrados == 0 && perguntasFiltradas == 0"
                      _ngcontent-c21=""
                      class="faixa4"
                      :codigo="municipio.codigo"
                      :nome="municipio.nome"
                      link=""
                    >
                      <g
                        @click="exibe_dados_municipio(municipio)"
                        v-html="municipio.poligono"
                      />
                    </g>
                    <g
                      v-else
                      _ngcontent-c21=""
                      class="faixa3"
                      :codigo="municipio.codigo"
                      :nome="municipio.nome"
                      link=""
                      @mousemove="exibirMunicipiosNomes($event, municipio)"
                    >
                      <g v-html="municipio.poligono" />
                    </g>
                  </g>
                </g>

                <!-- MAPA VARIÁVEL -->
                <g
                  v-if="regionaisSebraeFiltrados > 0 || projetosFiltrados > 0 || municipiosFiltrados > 0 || perguntasFiltradas > 0"
                  _ngcontent-c21=""
                  class="uf"
                  transform="scale(1 -1)"
                >
                  <g
                    v-for="(municipio) in municipiosFiltrados"
                    :key="municipio.municipioId"
                    _ngcontent-c21=""
                    vector-effect="non-scaling-stroke"
                    @mousemove="exibirMunicipiosNomes($event, municipio)"
                  >
                    <g
                      _ngcontent-c21=""
                      class="faixa5"
                      :codigo="municipio.codigo"
                      :nome="municipio.nome"
                      link=""
                    >
                      <g
                        @click="exibeDadosMunicipio(municipio)"
                        v-html="municipio.poligono"
                      />
                    </g>
                  </g>
                </g>

                <!-- MAPA DOS INDICADORES -->
                <g
                  _ngcontent-c21=""
                  class="uf"
                  transform="scale(1 -1)"
                >
                  <g
                    v-for="(municipio) in indicadoresFiltrados"
                    :key="municipio.municipioId"
                    _ngcontent-c21=""
                    vector-effect="non-scaling-stroke"
                    @mousemove="exibirMunicipiosNomes($event, municipio)"
                  >
                    <g
                      _ngcontent-c21=""
                      class="faixa6"
                      :codigo="municipio.codigo"
                      :nome="municipio.nome"
                      link=""
                    >
                      <g
                        @click="exibeDadosMunicipio(municipio)"
                        v-html="municipio.poligono"
                      />
                    </g>
                  </g>
                </g>
              </svg>
              <div
                class="description"
                :style="{
                  top: tooltipMap.pageY,
                  left: tooltipMap.pageX,
                  display: tooltipMap.disable,
                  position: 'absolute',
                }"
              >
                {{ municipioName }}
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- LISTA DE MUNICÍPIOS -->
    <v-row>
      <v-col>
        <div
          align="left"
          style="font-size: 20px"
        >
          <span v-if="perguntasFiltradas">
            <span
              v-for="municipio in municipiosFiltrados"
              :key="municipio.municipioId"
            >
              <b>{{ municipio.nome }}</b><br>
            </span>
          </span>
        </div>
      </v-col>
    </v-row>

    <!-- TABELA DE INDICADORES -->
    <v-row v-if="!editar">
      <v-col>
        <v-data-table
          :headers="headersIndicadoresMunicipios"
          :items="indicadoresMunicipios"
          class="elevation-1"
          hide-default-footer
          disable-pagination
        >
          <template slot="no-data">
            <span>
              Nenhum indicador retornado
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      estado: 'RJ',
      payload: {
        grupo: {},
        subgrupo: {},
        regiao: {}
      },
      regioes: [
        { regiaoId: 1, nome: 'teste' }
      ],
      projetos: [
        { projetoId: 1, nome: 'teste' }
      ],
      municipios: [],
      indicadores: [
        { indicadorId: 1, nome: 'teste' }
      ],
      indicadoresManuais: [
        { indicadorManualId: 1, nome: 'teste' }
      ],
      editar: false,
      headersIndicadoresMunicipios: [],
      indicadoresMunicipios: [
        { text: 'Título', value: 'nome' }
      ],
      svgUF: '-45.08576 20.567439999999998 4.322120000000005 2.996520000000004',
      regionaisSebraeFiltrados: [],
      projetosFiltrados: [],
      municipiosFiltrados: [],
      perguntasFiltradas: [],
      indicadoresFiltrados: [],
      tooltipMap: {
        pageX: 10 + 'px',
        pageY: 10 + 'px',
        disable: 'none'
      },
      municipioName: ''
    }
  },
  mounted () {
    this.listarMunicipios()
  },
  methods: {
    setSvgUF () {
      if (this.estado === 'RJ') {
        this.svgUF = '-45.08576 20.567439999999998 4.322120000000005 2.996520000000004'
      }
      if (this.estado === 'BA') {
        this.svgUF = '-47.080794999999995 8.069605000000001 10.203489999999995 10.73129'
      }
      if (this.estado === 'SC') {
        this.svgUF = '-54.112075 25.680325 6.060449999999996 3.9501500000000007'
      }
    },
    exibirMunicipiosNomes (e, municipio) {
      this.municipioName = municipio.nome
      this.tooltipMap = {
        pageX: e.pageX + 10 + 'px',
        pageY: e.pageY - 150 + 'px',
        disable: 'block'
      }
    },
    exibeDadosMunicipio () {
      return null
    },
    listarMunicipios () {
      axios.get(`https://api20210205161135.azurewebsites.net/api/geral/municipio`).then(response => {
        this.municipios = response.data.filter(response => {
          return response.uf === ('RJ')
        })
      })
    }
  }
}
</script>

<style lang="scss">
.description {
  pointer-events: none;
  position: absolute;
  font-size: 18px;
  text-align: center;
  background: white;
  padding: 10px 15px;
  z-index: 5;
  line-height: 30px;
  margin: 0 auto;
  color: #21669e;
  border-radius: 5px;
  box-shadow: 0 0 0 1px #eee;
  transform: translateX(-50%);

  &.active {
    display: block;
  }
  &:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 100%;
    width: 0;
    height: 0;
    margin-left: -10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
  }
}
svg[_ngcontent-c21]{
  -ms-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}

.container[_ngcontent-c21]{
  overflow: hidden;
}

.mapa[_ngcontent-c21]{
  width: 100%;
  margin-top: 0px;
}


.alturafixa_legenda[_ngcontent-c21] {
  height: 50px;
  margin-top:0px;
}

.ie10[_ngcontent-c21]   .mapa[_ngcontent-c21]   svg[_ngcontent-c21] {
  height: 600px;
}

@media all and (-ms-high-contrast:none) {
  .mapa[_ngcontent-c21]   svg[_ngcontent-c21] {
    height: 600px;
  }
}

.mapa__label--mun-selecionado[_ngcontent-c21] {
  width: 100%;
  padding-bottom: 3px;
}
.mapa__label--mun-selecionado[_ngcontent-c21]   .tip[_ngcontent-c21] {
  font-size: 0.7em;
  padding: 0;

}

#faixas[_ngcontent-c21]{
  padding:0 0 7px 5%;
}
#faixas[_ngcontent-c21] h4[_ngcontent-c21]{
  font-size: 0.85em;
  color: #FFF;
  margin:0 0 5px 0;
  padding:5px;
  text-align: center;
  background-color: #888;
}
.txt-faixa[_ngcontent-c21]{
  font-size: 0.82em;
  line-height: 23px;
  margin-left: 5px;
  vertical-align: bottom;
}
.ico-faixa[_ngcontent-c21]{
  display:inline-block;
  border:1px solid #aaa;
  width:20px;
  height:12px;
  margin-left: 10px;
}
.seletor-periodos[_ngcontent-c21]{
    text-align: center;
}
.periodo-link[_ngcontent-c21]:after {
    content: ' /';
}

g.faixa1[_ngcontent-c21] polygon[_ngcontent-c21]{ fill: #81BEF7; }
g.faixa2[_ngcontent-c21] polygon[_ngcontent-c21]{ fill: #5F9EA0; }
g.faixa4[_ngcontent-c21] polygon[_ngcontent-c21]{ fill: #04B486; }
g.faixa6[_ngcontent-c21] polygon[_ngcontent-c21]{ fill: #04B486; }

g.faixa1[_ngcontent-c22] polygon[_ngcontent-c22]{ fill: #81BEF7; }
g.faixa2[_ngcontent-c22] polygon[_ngcontent-c22]{ fill: #5F9EA0; }
g.faixa4[_ngcontent-c22] polygon[_ngcontent-c22]{ fill: #04B486; }
g.faixa6[_ngcontent-c22] polygon[_ngcontent-c22]{ fill: #04B486; }

polygon[_ngcontent-c21] {
  stroke: #fff;
  stroke-width: 0.01px;
  fill: #c2ccc9;
  cursor: default;
  -webkit-transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  transition: all 700ms ease;
}
g.checked[_ngcontent-c21]   polygon[_ngcontent-c21] {
  stroke: #fff;
  stroke-width: 0.01px;
  fill: #0D4768;
}
polygon[_ngcontent-c21]:hover {
fill-opacity: 0.5;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

g[_ngcontent-c21], .periodo-link[_ngcontent-c21] {
  cursor: pointer;
}
</style>
