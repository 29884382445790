import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VAutocomplete,{attrs:{"id":"uf-select","item-text":"uf","item-value":"uf","items":_vm.ufs,"label":"UF","placeholder":"Selecione uma  UF","return-object":true,"disabled":_vm.disabled,"multiple":"","loading":_vm.loading},on:{"change":_vm.emitValue},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index < _vm.maxDisplay && !item.headers)?_c(VChip,_vm._b({attrs:{"small":""}},'v-chip',item,false),[_c('span',{staticClass:"textoTemas"},[_vm._v(_vm._s(item.uf))])]):_vm._e(),(index === _vm.maxDisplay)?_c('span',{staticClass:"grey--text caption"},[(
          _vm.filter.uf.filter((item) => !item.headers).length - _vm.maxDisplay > 0
        )?[_vm._v(" (+"+_vm._s(_vm.filter.uf.filter((item) => !item.headers).length - _vm.maxDisplay)+" ufs)")]:_vm._e()],2):_vm._e()]}},{key:"item",fn:function({ item }){return [(!item.headers)?_c('div',{staticClass:"center"},[_vm._v(_vm._s(item.uf))]):_vm._e(),(item.headers)?_c('div',{staticClass:"headers center"},[_vm._v(_vm._s(item.uf))]):_vm._e()]}}]),model:{value:(_vm.filter.uf),callback:function ($$v) {_vm.$set(_vm.filter, "uf", $$v)},expression:"filter.uf"}},[(!_vm.loading)?_c('div',{staticStyle:{"display":"flex","padding":"0 16px","cursor":"pointer"},attrs:{"slot":"prepend-item","ripple":""},on:{"click":_vm.toggle},slot:"prepend-item"},[_c('div',{staticStyle:{"margin-right":"32px"}},[_c(VIcon,{attrs:{"color":_vm.filter.uf.length > 0 ? 'indigo darken-4' : ''}},[_vm._v(_vm._s(_vm.icon))])],1),_c('div',[_vm._v("Todos")])]):_vm._e(),_c(VDivider,{staticClass:"mt-2",attrs:{"slot":"prepend-item"},slot:"prepend-item"}),_c('template',{slot:"no-data"},[(_vm.loading)?_c('div',[_c(VCol,{attrs:{"align-self":"center"}},[(_vm.loading)?_c('div',{staticClass:"progress"},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e()])],1):_c('div',[_vm._v("Nenhuma UF retornada")])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }