<template>
  <div>
    <filtros @changeColor="(event) => (filtersColor = event)">
      <template v-slot:downloads>
        <div>
          <template v-if="mapa.totalMapaUfs && showFilters && !isSala">
            <div
              color="primary"
              class="relatorio mt-2"
              @click="gerarRelatorioEixos()"
            >
              <img
                src="/assets/img/excel.png"
                style="cursor: pointer; width: 25px"
              />
              Eixos por Período
            </div>
          </template>
          <template v-if="mapa.totalMapaUfs && showFilters && isSala">
            <div
              color="primary"
              class="relatorio mt-2"
              @click="
                abrirModalRelatorio(
                  `${
                    mapa.totalMapaIndicadores
                      ? 'getRelatorioIndicadorSelo'
                      : 'getRelatorioEixosSelo'
                  }`
                )
              "
            >
              <img
                src="/assets/img/excel.png"
                style="cursor: pointer; width: 25px"
              />
              Parceiros Habilitados
            </div>
            <!-- <div
              color="primary"
              class="relatorio mt-2"
              @click="abrirModalRelatorio('getRelatorioParceirosCriterios')"
            >
              <img
                src="/assets/img/excel.png"
                style="cursor: pointer; width: 25px"
              />
              Critérios respondidos
            </div>
            <div
              color="primary"
              class="relatorio mt-2"
              @click="abrirModalRelatorio('getRelatorioSituacaoGeral')"
            >
              <div>
                <img
                  src="/assets/img/excel.png"
                  style="cursor: pointer; width: 25px"
                />
              </div>

              Situação geral das evidencias enviadas
            </div>
            <div
              color="primary"
              class="relatorio mt-2"
              @click="abrirModalRelatorio('getQuantidadeSelosAtribuidos')"
            >
              <div>
                <img
                  src="/assets/img/excel.png"
                  style="cursor: pointer; width: 25px"
                />
              </div>

              Quantitativo de Selos por UF
            </div>

            <div
              color="primary"
              class="relatorio mt-2"
              @click="abrirModalRelatorioFiltro('gerarQuantidadeParceiros')"
            >
              <div>
                <img
                  src="/assets/img/excel.png"
                  style="cursor: pointer; width: 25px"
                />
              </div>

              Quantidades de salas por UF, Pilar e Faixa de Pontuação
            </div> -->
            <div
              color="primary"
              class="relatorio mt-2"
              @click="
                abrirModalRelatorioFiltro({
                  relatorio: 'gerarClienteOcultoConsolidadoSituacao',
                  filtros: [],
                })
              "
            >
              <div>
                <img
                  src="/assets/img/excel.png"
                  style="cursor: pointer; width: 25px"
                />
              </div>

              Cliente Oculto Consolidado por Situação
            </div>
            <div
              color="primary"
              class="relatorio mt-2"
              @click="
                abrirModalRelatorioFiltro({
                  relatorio: 'gerarExecucaoAnaliticaClienteOculto',
                  filtros: ['criterioSelo'],
                })
              "
            >
              <div>
                <img
                  src="/assets/img/excel.png"
                  style="cursor: pointer; width: 25px"
                />
              </div>

              Execução analítica do Cliente Oculto
            </div>
            <div
              color="primary"
              class="relatorio mt-2"
              @click="
                abrirModalRelatorioFiltro({
                  relatorio: 'gerarSituacaoDetalhadaEnvioEvidencia',
                  filtros: ['municipio'],
                })
              "
            >
              <div>
                <img
                  src="/assets/img/excel.png"
                  style="cursor: pointer; width: 25px"
                />
              </div>

              Situação Envio Evidências
            </div>
          </template>
          <div>
            <div
              v-if="
                (mapa.totalMapaUfs ||
                  mapa.totalMapaIndicadores ||
                  mapa.totalMapaMunicipio ||
                  mapa.totalMapaEixos ||
                  mapa.totalMapaUrs ||
                  mapa.totalMapaRelacionamentos) &&
                showFilters &&
                !isSala
              "
              class="relatorio mt-2"
              @click="gerarxlsx"
            >
              <img
                style="width: 25px; float: right"
                src="/assets/img/excel.png"
              />
              {{
                mapa.totalMapaIndicadores
                  ? "Indicadores por Municipio"
                  : "Eixos por Municipio"
              }}
            </div>
          </div>
        </div>
      </template>
    </filtros>

    <div>
      <div
        class="cardInfos"
        v-if="
          !mapa.totalMapaUfs &&
          !mapa.totalMapaIndicadores &&
          !mapa.totalMapaMunicipio &&
          !mapa.totalMapaEixos &&
          !mapa.totalMapaUrs &&
          !mapa.totalMapaRelacionamentos
        "
      >
        <div v-if="(isSala && !visualizarPontuacaoSelo) || !isSala">
          <div class="corPadrao" style="background: #b9e7f8"></div>
          {{
            isSala
              ? "Municípios com parceiros participantes"
              : "Destacando municípios vigentes"
          }}
        </div>

        <div v-if="!isSala" class="mt-2">
          menos pontos
          <div class="corPadrao" style="background: #b9e7f8"></div>
          <v-icon style="transform: rotate(180deg)"
            >mdi-keyboard-backspace</v-icon
          >
          <div class="corPadrao" style="background: #09344e"></div>
          mais pontos.
        </div>

        <div v-if="isSala && visualizarPontuacaoSelo" class="mt-2 part">
          <div>Participantes premiados</div>
          <div class="d-flex cores">
            <div class="d-flex mr-1">
              <div class="corPadrao mr-1" style="background: #b9e7f8"></div>
              Sem Selo
            </div>
            <div class="d-flex mr-1">
              <div class="corPadrao mr-1" style="background: #c66b3fb5"></div>
              Bronze
            </div>
            <div class="d-flex mr-1">
              <div
                class="corPadrao mr-1"
                style="background: rgb(145 145 145)"
              ></div>
              Prata
            </div>
            <div class="d-flex">
              <div class="corPadrao mr-1" style="background: #f8c33b"></div>
              Ouro
            </div>
          </div>
        </div>
      </div>
      <v-row v-else>
        <v-col cols="12">
          <div class="filtersColours" v-if="showAllLegends">
            <h1 @click="showAllLegends = false">
              {{ isSala ? "Parceiros" : "Municípios" }}

              <img
                style="
                  width: 13px;
                  margin-left: 2rem;
                  transform: rotate(179deg);
                "
                :src="'/assets/img/expand1.png'"
              />
            </h1>
            <div class="wrapper-filtros-opcoes">
              <div v-if="mapa.totalMapaUfs" class="filtrosOpcoes">
                <div class="titlesFiltros">
                  <div class="corUF">
                    {{ mapa.totalMapaUfs }}
                  </div>
                  <h1>UF</h1>
                </div>
                <div class="optionsTema">
                  <div v-for="uf in filter.uf" :key="uf.uf">
                    <div v-if="!uf.headers"><span>-</span> {{ uf.uf }}</div>
                  </div>
                </div>
              </div>
              <div v-if="mapa.totalMapaUrs" class="filtrosOpcoes">
                <div class="titlesFiltros">
                  <div class="corUR">
                    {{ mapa.totalMapaUrs }}
                  </div>
                  <h1>UR</h1>
                </div>
                <div class="optionsTema">
                  <div v-for="ur in filter.ur" :key="ur.id_unidade_regional">
                    <div><span>-</span> {{ ur.nomeRegiao }}</div>
                  </div>
                </div>
              </div>
              <div v-if="mapa.totalMapaRelacionamentos" class="filtrosOpcoes">
                <div class="titlesFiltros">
                  <div class="corRelacionamento">
                    {{ mapa.totalMapaRelacionamentos }}
                  </div>
                  <h1>Relacionamento</h1>
                </div>
                <div class="optionsTema">
                  <div
                    v-for="status in filter.relacionamentos"
                    :key="status.statusId"
                  >
                    <span>-</span> {{ status.statusNome }}
                  </div>
                </div>
              </div>
              <div v-if="mapa.totalMapaEixos" class="filtrosOpcoes">
                <div class="titlesFiltros">
                  <div class="corEixo">
                    {{ mapa.totalMapaEixos }}
                  </div>
                  <h1>Eixo</h1>
                </div>
                <div class="optionsTema">
                  <div v-for="eixo in filter.eixo" :key="eixo.statusContratoId">
                    <span>-</span> {{ eixo.nomeTema }}
                  </div>
                </div>
              </div>
              <div v-if="mapa.totalMapaIndicadores" class="filtrosOpcoes">
                <div class="titlesFiltros">
                  <div class="corIndicador">
                    {{ mapa.totalMapaIndicadores }}
                  </div>
                  <h1>{{ isSala ? "Critério" : "Indicador" }}</h1>
                </div>
                <div class="optionsTema">
                  <div
                    v-for="(indicador, index) in filter.indicador"
                    :key="index"
                  >
                    <span>-</span> {{ indicador.textoCompleto }}
                  </div>
                </div>
              </div>

              <div v-if="mapa.totalMapaMunicipio" class="filtrosOpcoes">
                <div class="titlesFiltros">
                  <div class="corMunicipio">
                    {{ mapa.totalMapaMunicipio }}
                  </div>
                  <h1>{{ filter.municipio.nome }}</h1>
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <div class="legendasReduzidas" v-if="!showAllLegends">
          <div class="legTitle" @click="showAllLegends = true">
            {{ isSala ? "Parceiros" : "Municípios" }}

            <img
              style="width: 13px; margin-left: 2rem"
              :src="'/assets/img/expand1.png'"
            />
          </div>
          <div class="filtrosOpcoes" v-if="mapa.totalMapaUfs">
            <div class="titlesFiltros">
              <div class="corUF">
                {{ mapa.totalMapaUfs }}
              </div>
              <h1>UF</h1>
            </div>
          </div>
          <div class="filtrosOpcoes" v-if="mapa.totalMapaUrs">
            <div class="titlesFiltros">
              <div class="corUR">
                {{ mapa.totalMapaUrs }}
              </div>
              <h1>UR</h1>
            </div>
          </div>

          <div class="filtrosOpcoes" v-if="mapa.totalMapaRelacionamentos">
            <div class="titlesFiltros">
              <div class="corRelacionamento">
                {{ mapa.totalMapaRelacionamentos }}
              </div>
              <h1>Relacionamento</h1>
            </div>
          </div>

          <div class="filtrosOpcoes" v-if="mapa.totalMapaEixos">
            <div class="titlesFiltros">
              <div class="corEixo">
                {{ mapa.totalMapaEixos }}
              </div>
              <h1>Eixo</h1>
            </div>
          </div>
          <div class="filtrosOpcoes" v-if="mapa.totalMapaIndicadores">
            <div class="titlesFiltros">
              <div class="corIndicador">
                {{ mapa.totalMapaIndicadores }}
              </div>
              <h1>{{ isSala ? "Critério" : "Indicador" }}</h1>
            </div>
          </div>
          <div class="filtrosOpcoes" v-if="mapa.totalMapaMunicipio">
            <div class="titlesFiltros">
              <div class="corMunicipio">
                {{ mapa.totalMapaMunicipio }}
              </div>
              <h1>{{ filter.municipio.nome }}</h1>
            </div>
          </div>
        </div>
      </v-row>
      <!-- FIM DO TÍTULO -->
      <v-row>
        <div _ngcontent-c21="" class="mapa">
          <div _ngcontent-c21="" class="" style="position: relative">
            <SvgPanZoom
              v-if="!isMobileAndTabletCheck()"
              style="width: 100vw; height: calc(100vh - 100px)"
              @svgpanzoom="registerSvgPanZoom"
            >
              <svg
                style="width: 100%; height: 100%"
                _ngcontent-c21=""
                xmlns:xlink="http://www.w3.org/1999/xlink"
                preserveAspectRatio="xMidYMid meet"
                xmlns="http://www.w3.org/2000/svg"
                :viewBox="$store.state.svg"
                @mouseout="disabledTooltip = true"
              >
                <g
                  class="uf"
                  transform="scale(1 -1)"
                  style="width: 100%; height: 100%"
                >
                  <g
                    v-for="municipio in mapaFixo"
                    :key="municipio.codigoIBGE"
                    vector-effect="non-scaling-stroke"
                  >
                    <g
                      v-if="mapa.totalMapaIndicadores === 0"
                      :class="
                        municipio.class ||
                        colourByPontos(
                          municipio.pontos || municipio.pontosPerguntas,
                          municipio.statusId,
                          municipio.desabilitado
                        ) ||
                        'opacity'
                      "
                      :codigo="municipio.codigoIBGE"
                      :nome="municipio.nome"
                      :pontos="municipio.pontos || municipio.pontosPerguntas"
                    >
                      <g
                        @click="exibeDadosMunicipio(municipio)"
                        @mouseover="exibirMunicipiosNomes($event, municipio)"
                        v-html="municipio.poligono"
                      />
                    </g>
                    <g
                      v-else
                      _ngcontent-c21=""
                      :class="
                        municipio.class ||
                        colourByPontos(
                          municipio.pontos || municipio.pontosPerguntas,
                          municipio.statusId,
                          municipio.desabilitado
                        ) ||
                        'opacity'
                      "
                      :codigo="municipio.codigoIBGE"
                      :nome="municipio.nome"
                    >
                      <g
                        @click="exibeDadosMunicipio(municipio)"
                        @mouseover="exibirMunicipiosNomes($event, municipio)"
                        v-html="municipio.poligono"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </SvgPanZoom>
            <v-content v-else>
              <svg
                style="width: 100%; height: 100%"
                _ngcontent-c21=""
                xmlns:xlink="http://www.w3.org/1999/xlink"
                preserveAspectRatio="xMidYMid meet"
                xmlns="http://www.w3.org/2000/svg"
                :viewBox="$store.state.svg"
                @mouseout="disabledTooltip = true"
              >
                <g
                  class="uf"
                  transform="scale(1 -1)"
                  style="width: 100%; height: 100%"
                >
                  <g
                    v-for="municipio in mapaFixo"
                    :key="municipio.codigoIBGE"
                    vector-effect="non-scaling-stroke"
                  >
                    <g
                      v-if="totalMapaIndicadores === 0"
                      :class="
                        municipio.class ||
                        colourByPontos(
                          municipio.pontos || municipio.pontosPerguntas,
                          municipio.statusId,
                          municipio.desabilitado
                        ) ||
                        'opacity'
                      "
                      :codigo="municipio.codigoIBGE"
                      :nome="municipio.nome"
                      :pontos="municipio.pontos || municipio.pontosPerguntas"
                    >
                      <g
                        @click="exibeDadosMunicipio(municipio)"
                        @mouseover="exibirMunicipiosNomes($event, municipio)"
                        v-html="municipio.poligono"
                      />
                    </g>
                    <g
                      v-else
                      _ngcontent-c21=""
                      :class="
                        municipio.class ||
                        colourByPontos(
                          municipio.pontos || municipio.pontosPerguntas,
                          municipio.statusId,
                          municipio.desabilitado
                        ) ||
                        'opacity'
                      "
                      :codigo="municipio.codigoIBGE"
                      :nome="municipio.nome"
                    >
                      <g
                        @click="exibeDadosMunicipio(municipio)"
                        @mouseover="exibirMunicipiosNomes($event, municipio)"
                        v-html="municipio.poligono"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </v-content>

            <div
              v-if="$store.state.uf !== 'NA' || isFilteringData(municipioObj)"
              class="municipioTitle"
              :style="{ display: disabledTooltip ? 'none' : 'block' }"
            >
              {{ municipioName }} - {{ municipioUf }}
            </div>
          </div>
        </div>
      </v-row>
      <app-modal-indicador-game
        :municipio="municipioSelecionado"
        :exibe-modal="exibeModal"
        :itens="[{ titulo: 'Dados Gerais', texto: '1' }]"
        @fecharModal="fecharModal"
        @closeModal="exibeModal = false"
      />
      <modal-relatorio
        :tipoRelatorio="tipoRelatorio"
        :exibe-modal="exibeRelatorio"
        @fecharRelatorio="exibeRelatorio = false"
        :uf="
          this.mapa.filter.uf &&
          this.filter.uf
            .map((item) => {
              if (!item.headers) return item.uf;
            })
            .filter((val) => val != undefined)
            .join(',')
        "
      ></modal-relatorio>
      <modal-relatorio-filtros
        :tipoRelatorio="tipoRelatorio"
        :filtrosRelatorio="filtrosRelatorio"
        :exibe-modal="exibeRelatorioFiltro"
        @fecharRelatorio="exibeRelatorioFiltro = false"
        :uf="
          this.mapa.filter.uf &&
          this.filter.uf
            .map((item) => {
              if (!item.headers) return item.uf;
            })
            .filter((val) => val != undefined)
            .join(',')
        "
      ></modal-relatorio-filtros>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AppModalIndicadorGame from "./ModalIndicadorGame";
import { MunicipioCombobox, municipioAPI } from "@/modules/municipio";
import { UFCombobox } from "@/modules/uf";
import { RelacionamentoCombobox } from "@/modules/relacionamento";
// import { ContratoCombobox } from "@/modules/contrato";
import { EixoCombobox } from "@/modules/eixo";
import { IndicadorCombobox } from "@/modules/game";
import { AppUrCombobox } from "@/modules/ur";
import filtros from "@/components/mapa/components/Filtro";

// import { AppProjetoCombobox } from '@/modules/projeto'
import SvgPanZoom from "vue-svg-pan-zoom";
import moment from "moment";

import baseAPI from "@/http";
import { mobileAndTabletCheck } from "../../../helpers/utils";
import { ColorPicker } from "@/components";
import {
  statusRelacionamentoEnum as relacionamentoEnum,
  statusRelacionamentoSeloEnum,
} from "@/types";

import { configuracaoAPI } from "@/apis/configuracao";
import ModalRelatorio from "./relatorios/ModalRelatorio.vue";
import ModalRelatorioFiltros from "./relatorios/ModalRelatorioFiltros.vue";

const router = "api/municipio/municipio";

export default {
  components: {
    AppModalIndicadorGame,
    MunicipioCombobox,
    IndicadorCombobox,
    UFCombobox,
    SvgPanZoom,
    RelacionamentoCombobox,
    // ContratoCombobox,
    EixoCombobox,
    AppUrCombobox,
    ColorPicker,
    filtros,
    ModalRelatorio,
    ModalRelatorioFiltros,
  },
  computed: {
    ...mapGetters([
      "isLogged",
      "isAuthenticated",
      "isConsultor",
      "isAdmin",
      "loading",
      "user",
      "uf",
      "logo",
      "svg",
      "mapa",
      "isSala",
    ]),
    mapaFixo() {
      return this.mapa.mapaFixo;
    },
    filter() {
      return this.mapa.filter;
    },
  },

  data() {
    return {
      filtersColor: {
        uf: "#3e2577",
        contrato: "#fdc70c",
        relacionamento: "#e93e3a",
        region: "#f3903f",
        indicador: "#257725",
        eixo: "#993399",
        municipio: "#002947",
      },
      headers: [
        { text: "Município", value: "nome" },
        { text: "Detalhes", value: "acao" },
      ],
      municipioSelecionado: {},
      disabledTooltip: false,
      options: {
        text: {
          color: "#FFFFFF",
          shadowEnable: true,
          shadowColor: "#000000",
          fontSize: 14,
          fontFamily: "Helvetica",
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: "#086a87",
          backgroundColor: "#333333",
          inverted: false,
        },
        layout: {
          height: 50,
          width: 100,
          verticalTextAlign: 35,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 10,
          type: "circle",
        },
      },
      percente: 0,
      svgpanzoom: null,
      showAllLegends: false,
      showFilters: true,
      showLegends: true,
      mapaTotal: [],
      municipiosCE: [],
      mapaUrs: [],
      exibeModal: false,
      municipioUf: "",
      municipioName: "",
      municipioObj: {},
      loadingMap: true,
      visualizarPontuacaoSelo: false,
      tipoRelatorio: "",
      filtrosRelatorio: [],
      exibeRelatorio: false,
      exibeRelatorioFiltro: false,
    };
  },
  methods: {
    ...mapActions([
      "setLoading",
      "setToken",
      "setUser",
      "setAdmin",
      "setExpired",
      "setUf",
      "setLogo",
      "setSvg",
      "setMapaVariables",
      "setMapaInicial",
    ]),
    abrirModalRelatorio(val) {
      this.tipoRelatorio = val;
      this.exibeRelatorio = true;
    },
    abrirModalRelatorioFiltro(val) {
      this.tipoRelatorio = val.relatorio;
      this.filtrosRelatorio = val.filtros;
      this.exibeRelatorioFiltro = true;
    },
    isMobileAndTabletCheck() {
      return mobileAndTabletCheck();
    },
    async gerarRelatorioEixos() {
      this.setLoading(true);
      let mapearFilter = {
        uf:
          (this.filter.uf &&
            this.filter.uf
              .filter((item) => !item.headers)
              .map((a) => `${a.uf}`)
              .join(",")) ||
          ufNA,
        statusId:
          (this.filter.relacionamentos &&
            this.filter.relacionamentos
              .map((item) => item.statusId)
              .join(",")) ||
          null,
        statusContratoId:
          (this.filter.contrato &&
            this.filter.contrato
              .map((item) => item.statusContratoId)
              .join(",")) ||
          null,
        eixoId:
          (this.filter.eixo.length &&
            this.filter.eixo.map((item) => item.temaId).join(",")) ||
          null,
        eixoTipo: this.filter.eixoTipo,
        indicador:
          (this.filter.indicador.length &&
            this.filter.indicador.map((item) => item.perguntaId).join(",")) ||
          null,
        indicadorTipo: this.filter.indicadorTipo,
        ur:
          (this.filter.ur.length &&
            this.filter.ur.map((item) => item.id_unidade_regional).join(",")) ||
          null,
      };

      const nomeRelatorio = "Eixos por Período - ";

      const response = await municipioAPI.gerarXlsEixoPeriodo(mapearFilter);

      this.fazerDownloadRelatorio(response.data, nomeRelatorio);
      this.setLoading(false);
    },
    async gerarxlsx() {
      this.setLoading(true);
      let ufNA;
      if (this.$store.state.uf === "NA") {
        ufNA = "NA";
      } else {
        ufNA = null;
      }
      let mapearFilter = {
        uf:
          (this.filter.uf &&
            this.filter.uf
              .filter((item) => !item.headers)
              .map((a) => `${a.uf}`)
              .join(",")) ||
          ufNA,
        statusId:
          (this.filter.relacionamentos &&
            this.filter.relacionamentos
              .map((item) => item.statusId)
              .join(",")) ||
          null,
        statusContratoId:
          (this.filter.contrato &&
            this.filter.contrato
              .map((item) => item.statusContratoId)
              .join(",")) ||
          null,
        eixoId:
          (this.filter.eixo.length &&
            this.filter.eixo.map((item) => item.temaId).join(",")) ||
          null,
        eixoTipo: this.filter.eixoTipo,
        indicador:
          (this.filter.indicador.length &&
            this.filter.indicador.map((item) => item.perguntaId).join(",")) ||
          null,
        indicadorTipo: this.filter.indicadorTipo,
        ur:
          (this.filter.ur.length &&
            this.filter.ur.map((item) => item.id_unidade_regional).join(",")) ||
          null,
      };

      let response = null;

      var nomeRelatorioIndicador = "Indicadores por município e UF - ";
      var nomeRelatorioEixo = "Eixos por município e UF -";

      if (this.mapa.totalMapaIndicadores) {
        response = await municipioAPI.gerarXlsxIndicador(mapearFilter);
      } else {
        response = await municipioAPI.gerarXlsx(mapearFilter);
      }

      if (this.isSala) {
        nomeRelatorioIndicador = "Critérios por parceiro e UF - ";
        nomeRelatorioEixo = "Parceiros por UF -";
      }

      this.fazerDownloadRelatorio(
        response.data,
        !this.mapa.totalMapaIndicadores
          ? nomeRelatorioEixo
          : nomeRelatorioIndicador
      );
      this.setLoading(false);
    },
    fazerDownloadRelatorio(file, nomeArquivo) {
      var fileURL = window.URL.createObjectURL(new Blob([file]));
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        nomeArquivo + moment().format("DD.MM.YYYY") + ".xlsx"
      );

      document.body.appendChild(fileLink);
      fileLink.click();
    },
    async geraPdf() {
      this.setLoading(true);
      let ufNA;
      if (this.$store.state.uf === "NA") {
        ufNA = "NA";
      } else {
        ufNA = null;
      }
      let mapearFilter = {
        uf:
          (this.filter.uf &&
            this.filter.uf
              .filter((item) => !item.headers)
              .map((a) => `'${a.uf}'`)
              .join(",")) ||
          ufNA,
        statusId:
          (this.filter.relacionamento &&
            this.filter.relacionamento
              .map((item) => item.statusId)
              .join(",")) ||
          null,
        statusContratoId:
          (this.filter.contrato &&
            this.filter.contrato
              .map((item) => item.statusContratoId)
              .join(",")) ||
          null,
        eixoId:
          (this.filter.eixo &&
            this.filter.eixo.map((item) => item.temaId).join(",")) ||
          null,
      };

      let response = null;

      if (this.mapa.totalMapaIndicadores) {
        response = await municipioAPI.gerarPdfIndicador(mapearFilter);
      } else {
        response = await municipioAPI.gerarPdfEixos(mapearFilter);
      }

      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        !this.mapa.totalMapaIndicadores
          ? "Eixos por município e UF -" +
              moment().format("DD.MM.YYYY") +
              ".pdf"
          : "Indicadores por município e UF - " +
              moment().format("DD.MM.YYYY") +
              ".pdf"
      );

      document.body.appendChild(fileLink);
      fileLink.click();
      this.setLoading(false);
    },
    registerSvgPanZoom(svgpanzoom) {
      this.svgpanzoom = svgpanzoom;
      this.center();
    },
    center() {
      if (!this.svgpanzoom) return;

      this.svgpanzoom.center();
    },
    exibeDadosMunicipio(municipio) {
      if (this.isFilteringData(municipio)) {
        this.municipioSelecionado = municipio;
        this.exibeModal = true;
      }
    },
    isFilteringData(municipio) {
      if (!this.isSala) {
        if (
          (municipio.class && municipio.class.startsWith("cor")) ||
          (municipio.pontos && municipio.pontos > 0) ||
          (municipio.pontosPerguntas && municipio.pontosPerguntas > 0)
        ) {
          return true;
        }
        return false;
      } else {
        if (municipio.statusId === statusRelacionamentoSeloEnum.participante) {
          return true;
        }
      }
    },
    fecharModal() {
      this.exibeModal = false;
    },
    exibirMunicipiosNomes(e, municipio) {
      this.disabledTooltip = false;

      this.municipioName = municipio.nome;
      this.municipioUf = municipio.uf;
      this.municipioObj = municipio;
    },
    getMunicipiosCE() {
      this.setLoading(true);
      municipioAPI
        .getMunicipiosCE(this.$store.state.uf)
        .then((resp) => {
          this.percente = 50;
          this.municipiosCE = resp.data;
          this.$forceUpdate();
          this.getMapaFixo();
        })
        .catch(() => {
          this.$notification.error(
            `Erro ao listar ${this.isSala ? "Parceiros" : "Municípios"}`
          );
          this.setLoading(false);
        });
    },
    colourByPontos(ponto, relacionamento, desabilitado) {
      if (relacionamento === relacionamentoEnum.vigente && !this.isSala) {
        if (ponto === 0 || !ponto) {
          return "zeroVigente";
        } else if (ponto > 0 && ponto < 2000) {
          return "vinte";
        } else if (ponto > 0 && ponto < 4000) {
          return "trinta";
        } else if (ponto > 0 && ponto < 6000) {
          return "quarenta";
        } else if (ponto > 0 && ponto < 8000) {
          return "sessenta";
        } else if (ponto > 0 && ponto < 10000) {
          return "oitenta";
        } else if (ponto > 0 && ponto < 13000) {
          return "cem";
        } else if (ponto > 0 && ponto < 20000) {
          return "cem2";
        } else if (ponto > 0 && ponto < 50000) {
          return "cem3";
        } else if (ponto > 0 && ponto < 100000) {
          return "cem4";
        }
      } else if (
        relacionamento === statusRelacionamentoSeloEnum.participante &&
        this.isSala &&
        !desabilitado
      ) {
        if (!this.visualizarPontuacaoSelo) return "zeroVigente";
        else {
          if (ponto === 0 || !ponto || ponto <= 75) {
            return "zeroVigente";
          } else if (ponto > 75 && ponto < 81) {
            return "bronze";
          } else if (ponto >= 81 && ponto < 90) {
            return "prata";
          } else if (ponto >= 90) {
            return "ouro";
          }
          // } else if ((ponto) => 99) {
          //   return "diamante";
          // }
        }
      }
    },
    getMapaFixo() {
      baseAPI
        .get(`${router}` + "/get-mapa-fixo", {
          onDownloadProgress: (progressEvent) => {
            let percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            this.percente =
              this.percente + percentCompleted > 100
                ? 100
                : this.percente + percentCompleted;
          },
        })
        .then((resp) => {
          var mapa = resp.data;

          this.mapaTotal = resp.data;

          let tempHash = {};
          let tempHashId = {};

          this.municipiosCE.forEach((m) => {
            if (!tempHash[m.codigoIbge]) {
              tempHash[m.codigoIbge] = m.pontos;
            }
            if (!tempHashId[m.codigoIbge]) {
              tempHashId[m.codigoIbge] = m.id_Unidade_Regional;
            }
          });

          mapa.forEach((item) => {
            item.pontos = tempHash[item.codigoIBGE];
            item.id_Unidade_Regional = tempHashId[item.codigoIBGE];
          });
          var t = mapa;

          this.setMapaInicial(t);

          this.setMapaVariables({
            mapaFixo: t,
          });

          this.setMapaVariables({ nome: "mapaFixo", value: mapa });

          this.setLoading(false);
        })
        .catch(() => {
          this.$notification.error("Erro ao carregar");
          this.loadingMap = false;
        });
    },
    getMunicipiosNA() {
      this.setLoading(true);
      municipioAPI.getMunicipiosNA().then((resp) => {
        this.municipiosCE = resp.data.map((item) => {
          return { ...item, codigoIbge: `${item.codigoIBGE}` };
        });

        this.mapaTotal = resp.data.map((item) => {
          return { ...item, codigoIBGE: `${item.codigoIBGE}` };
        });

        var t = resp.data;
        this.setMapaInicial(t);

        this.setMapaVariables({
          mapaFixo: t,
        });

        this.setLoading(false);
      });
    },
  },
  async mounted() {
    if (this.isSala) {
      try {
        const { data: config } = await configuracaoAPI.list();
        this.visualizarPontuacaoSelo = config[0].visualizarPontuacaoSelo;
      } catch (error) {
        this.$notification.error("Erro ao listar as configuracoes");
        console.log("error: ", error);
      }
    }

    if (this.$store.state.uf == "NA") {
      this.getMunicipiosNA();
    } else {
      this.getMunicipiosCE();
    }
    if (localStorage.getItem("mapFiltersColors")) {
      this.filtersColor = JSON.parse(localStorage.getItem("mapFiltersColors"));
    }
  },
};
</script>

<style lang="scss">
$mapaRelacionamentoColour: v-bind("filtersColor.relacionamento");
$mapaContratoColour: v-bind("filtersColor.contrato");
$mapaRegionColour: v-bind("filtersColor.region");
$mapaMunicipioColour: v-bind("filtersColor.municipio");
$mapaIndicadorColour: v-bind("filtersColor.indicador");
$mapaUFColour: v-bind("filtersColor.uf");
$mapaEixoColour: v-bind("filtersColor.eixo");

.cardInfos {
  background: #fff;
  padding: 1rem;
  -webkit-box-shadow: 0 0 1em #e9e9e8;
  box-shadow: 0 0 1em #e9e9e8;
  position: absolute;
  bottom: 10px;
  left: 10px;
  max-width: 400px;
  z-index: 2;

  > div {
    display: flex;
    column-gap: 5px;
    justify-content: center;
  }
  > div:first-of-type {
    font-weight: bold;
  }
  .corPadrao {
    width: 20px;
    height: 20px;
    &.vigentes {
      background: #a4d0da;
    }
    &.naoVigentes {
      background: #c2ccc9;
    }
  }
}

.part {
  flex-direction: column;
}

.filtersSettings {
  padding-right: 20px;
  > div {
    display: flex;
    align-items: center;
    column-gap: 15px;
  }
}

.divLegends {
  position: absolute;
  bottom: 3rem;
  right: 16rem;
  background: #3a1160;
}

.legendas {
  // width: 100%;
  //padding: 0;
  box-shadow: none;
  background-color: #3a1160 !important;
  color: #fff !important;
  text-transform: capitalize;
  position: absolute;
  bottom: 0;
  left: 0;
}

.corMunicipio {
  color: #fff;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  background: $mapaMunicipioColour;

  polygon {
    fill: $mapaMunicipioColour !important;
  }
}

.corEixo {
  color: #fff;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  background: $mapaEixoColour;

  polygon {
    fill: $mapaEixoColour !important;
  }
}

.corProjeto {
  color: #fff;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  background: $mapaMunicipioColour;

  polygon {
    fill: $mapaMunicipioColour !important;
  }
}
.cores {
  justify-content: space-between;
}

.relatorio {
  background: var(--corPrimaria);
  margin-right: 20px;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  column-gap: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  font-weight: bold;
}

.corContrato {
  color: #fff;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  background: $mapaContratoColour;

  polygon {
    fill: $mapaContratoColour !important;
  }
}

.corRelacionamento {
  color: #fff;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  background: $mapaRelacionamentoColour;

  polygon {
    fill: $mapaRelacionamentoColour !important;
  }
}

.corUF {
  color: #fff;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  background: $mapaUFColour;

  polygon {
    fill: $mapaUFColour !important;
  }
}

.corIndicador {
  color: #fff;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  background: $mapaIndicadorColour;

  polygon {
    fill: $mapaIndicadorColour !important;
  }
}

.corUR {
  color: #fff;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  background: $mapaRegionColour;

  polygon {
    fill: $mapaRegionColour !important;
  }
}

.fechar {
  text-align: center;
  position: absolute;
  left: 36%;
}

.centerMapa {
  display: flex;
  align-items: center;
  justify-content: center;
}

.filtrosOpcoes {
  display: flex;
  margin-bottom: 1.5rem;

  .titlesFiltros {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    min-width: 219px;
  }

  .titlesFiltros > div:first-of-type {
    width: 3rem;
    display: block;
    height: 18px;
    border-radius: 20px;
  }

  h1 {
    font-size: 16px;
    margin-left: 1rem;
  }
}

.filtersColours {
  width: fit-content;
  padding: 1rem 0;
  max-width: 455px;
  position: absolute;
  bottom: 49px;
  right: 52px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 0 1em #e9e9e8;
  z-index: 2;
  overflow: hidden;

  h1 {
    font-size: 16px;
    margin-left: 1rem;
  }

  > h1 {
    margin-bottom: 1rem;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
  }

  .optionsTema {
    > div {
      margin-left: 1rem;
      font-size: 12px;
      font-weight: 500;

      span {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}

.closeFilters {
  background: transparent !important;
  border: none;
  box-shadow: none;
  position: absolute;
  top: 10px;
  right: 0;
}

.bronze {
  polygon {
    fill: #c66b3fb5 !important;
  }
}

.ouro {
  polygon {
    fill: #f8c33b !important;
  }
}

.prata {
  polygon {
    fill: rgb(145 145 145) !important;
  }
}

.diamante {
  polygon {
    fill: #51b9e8 !important;
  }
}

.zeroVigente {
  polygon {
    fill: #a4d0da !important;
  }
}
.zero {
  polygon {
    fill: #cfeffc !important;
  }
}

.vinte {
  polygon {
    fill: #b9e7f8 !important;
  }
}

.trinta {
  polygon {
    fill: #a2def6 !important;
  }
}

.quarenta {
  polygon {
    fill: #8ad6f4 !important;
  }
}

.sessenta {
  polygon {
    fill: #77cff2 !important;
  }
}

.oitenta {
  polygon {
    fill: #58c7f3 !important;
  }
}

.cem {
  polygon {
    fill: #41c0f2 !important;
  }
}

.cem1 {
  polygon {
    fill: #0fb2f2 !important;
  }
}

.cem2 {
  polygon {
    fill: #0e9af1 !important;
  }
}
.cem3 {
  polygon {
    fill: #0d8bd9 !important;
  }
}

.cem4 {
  polygon {
    fill: #0c5685 !important;
  }
}

.cem4 {
  polygon {
    fill: #09344e !important;
  }
}

.wrapper-filtros-opcoes {
  max-height: 60vh;
  overflow: auto;
  padding: 0 1rem;
}

.legendasReduzidas {
  position: absolute;
  right: 0;
  background: #fff;
  padding: 1rem;
  box-shadow: 0 0 1em #e9e9e8;
  bottom: 3rem;
  right: 2rem;
  z-index: 2;

  .legTitle {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 1rem;
    text-align: center;
    cursor: pointer;
  }

  .filtrosOpcoes {
    margin-bottom: 0;

    .titlesFiltros {
      min-width: unset;
    }

    .titlesFiltros:last-of-type {
      margin-bottom: 0;
    }
  }
}

.showFilters {
  background: #fff;
  box-shadow: 0px 4px 19px #e1e1e1;
}

@media (max-width: 1300px) {
  .filtersColours {
    width: fit-content;
    padding: 1rem;
    max-width: 363px;
    position: absolute;
    bottom: 26rem;
    right: 4vw;
    background: #f5f5f5;
    border-radius: 6px;
    -webkit-box-shadow: 0 0 1em #e9e9e8;
    box-shadow: 0 0 1em #e9e9e8;

    h1 {
      font-size: 14px;
      margin-left: 1rem;
    }
  }
}

@media screen and (max-width: 800px) {
  .mapa > div > div {
    width: 93vw !important;
  }

  .col-2 {
    max-width: 100% !important;
  }

  .filters {
    padding: 10px 28px;

    > .row {
      flex-direction: column;
    }
  }
}

svg[_ngcontent-c21],
svg[_ngcontent-c22] {
  width: 100%;
  height: 100%;

  -ms-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}

polygon {
  fill: #c2ccc9;
}

.municipioTitle {
  pointer-events: none;
  position: absolute;
  font-size: 18px;
  text-align: center;
  background: var(--corPrimaria);
  padding: 10px 15px;
  z-index: 5;
  right: 5%;
  top: 15px;
  line-height: 30px;
  margin: 0 auto;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 0 1px #eee;
}

.target {
  fill: none;
  pointer-events: visibleFill;
}

svg:hover g:not(:hover) text {
  opacity: 0;
}

.container[_ngcontent-c21],
.container[_ngcontent-c22] {
  overflow: hidden;
}

.mapa[_ngcontent-c21],
.mapa[_ngcontent-c22] {
  width: 100%;
  margin-top: 0px;
}

.alturafixa_legenda[_ngcontent-c21],
.alturafixa_legenda[_ngcontent-c22] {
  height: 50px;
  margin-top: 0px;
}

.mapa__label--mun-selecionado[_ngcontent-c21],
.mapa__label--mun-selecionado[_ngcontent-c22] {
  width: 100%;
  padding-bottom: 3px;
}

.mapa__label--mun-selecionado[_ngcontent-c21] .tip[_ngcontent-c21],
.mapa__label--mun-selecionado[_ngcontent-c22] .tip[_ngcontent-c22] {
  font-size: 0.7em;
  padding: 0;
}

#faixas[_ngcontent-c21],
#faixas[_ngcontent-c22] {
  padding: 0 0 7px 5%;
}

#faixas[_ngcontent-c21] h4[_ngcontent-c21],
#faixas[_ngcontent-c22] h4[_ngcontent-c22] {
  font-size: 0.85em;
  color: #fff;
  margin: 0 0 5px 0;
  padding: 5px;
  text-align: center;
  background-color: #888;
}

.txt-faixa[_ngcontent-c21],
.txt-faixa[_ngcontent-c22] {
  font-size: 0.82em;
  line-height: 23px;
  margin-left: 5px;
  vertical-align: bottom;
}

.ico-faixa[_ngcontent-c21],
.ico-faixa[_ngcontent-c22] {
  display: inline-block;
  border: 1px solid #aaa;
  width: 20px;
  height: 12px;
  margin-left: 10px;
}

.seletor-periodos[_ngcontent-c21],
.seletor-periodos[_ngcontent-c22] {
  text-align: center;
}

.periodo-link[_ngcontent-c21]:after,
.periodo-link[_ngcontent-c22]:after {
  content: " /";
}

polygon[_ngcontent-c21],
polygon[_ngcontent-c22] {
  stroke: #fff;
  stroke-width: 0.01px;
  cursor: default;
  -webkit-transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  transition: all 700ms ease;
}

g.checked[_ngcontent-c21] polygon[_ngcontent-c21],
g.checked[_ngcontent-c22] polygon[_ngcontent-c22] {
  stroke: #fff;
  stroke-width: 0.01px;
  fill: #0d4768;
}

polygon[_ngcontent-c21]:hover,
polygon[_ngcontent-c22]:hover {
  fill-opacity: 0.5;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

g[_ngcontent-c21],
.periodo-link[_ngcontent-c21] {
  cursor: pointer;
}

g[_ngcontent-c22],
.periodo-link[_ngcontent-c22] {
  cursor: pointer;
}

.limpar {
  background-color: #fff !important;
  border: none !important;
  color: #000;
  box-shadow: none;
}

.filterbtn {
  background-color: #086a87 !important;
  color: #fff !important;
}

.loadingMap {
  position: absolute;
  z-index: 99999;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  background: #00000042;
  display: flex;
  align-items: center;
  justify-content: center;
}

.legendColor {
  color: #fff;
}
</style>
