<template>
  <v-select
    id="relacionamento-select"
    v-model="filter.relacionamento"
    item-text="statusNome"
    item-value="statusId"
    :items="relacionamentos"
    label="Relacionamento"
    placeholder="Selecione um Relacionamento"
    :return-object="true"
    :disabled="disabled"
    multiple
    @change="emitValue"
  >
    <div
      style="display: flex; padding: 0 16px; cursor: pointer"
      slot="prepend-item"
      ripple
      @click="toggle"
    >
      <div style="margin-right: 32px">
        <v-icon
          :color="filter.relacionamento.length > 0 ? 'indigo darken-4' : ''"
          >{{ icon }}</v-icon
        >
      </div>
      <div>Todos</div>
    </div>
    <v-divider slot="prepend-item" class="mt-2" />
    <template v-slot:selection="{ item, index }">
      <v-chip v-bind="item" small v-if="index < maxDisplay">
        <span class="textoTemas">{{ item.statusNome }}</span>
      </v-chip>
      <span v-if="index === maxDisplay" class="grey--text caption"
        >(+{{
          filter.relacionamento.length - maxDisplay
        }}
        relacionamentos)</span
      >
    </template>
    <template slot="no-data"> Nenhum Relacionamento retornado </template>
  </v-select>
</template>

<script>
import { municipioAPI } from "@/modules/municipio";

export default {
  name: "AppRelacionamentoCombobox",
  props: {
    filter: { type: Object, required: true },
    disabled: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      relacionamentos: [],
      maxDisplay: 1,
    };
  },
  computed: {
    likesAll() {
      return this.filter.relacionamento.length === this.relacionamentos.length;
    },
    likesSome() {
      return this.filter.relacionamento.length > 0 && !this.relacionamentos;
    },
    icon() {
      if (this.likesAll) return "mdi-close-box";
      if (this.likesSome) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  mounted() {
    this.list();
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.likesAll) {
          this.filter.relacionamento = [];
          this.$emit("changeRelacionamento", this.filter.relacionamento);
        } else {
          this.filter.relacionamento = this.relacionamentos.slice();
          this.$emit("changeRelacionamento", this.filter.relacionamento);
        }
      });
    },
    emitValue(e) {
      this.$emit("changeRelacionamento", e);
    },
    list() {
      municipioAPI
        .getStatus()
        .then((resp) => {
          this.relacionamentos = resp.data;
        })
        .catch(() => {
          this.$notification.error("Erro ao listar Relacionamentos");
        });
    },
  },
};
</script>
