<template>
  <div
    :class="`filters ${showFilters ? 'showFilters' : ''} ${
      filter.uf.length > 0 ||
      filter.indicador.length > 0 ||
      filter.municipio.codigoIbge ||
      filter.ur.length > 0 ||
      filter.eixo.length > 0 ||
      filter.relacionamento.length > 0 ||
      filter.contrato.length > 0
        ? 'large'
        : 'small'
    }`"
  >
    <v-btn
      v-if="!showFilters"
      @click="showFilters = true"
      style="background: #086a87; color: #fff"
    >
      Filtros

      <span style="margin-left: 1rem">
        <img style="width: 10px" src="/assets/img/add.svg" />
      </span>
    </v-btn>
    <v-btn class="closeFilters" v-if="showFilters" @click="showFilters = false">
      <span>
        <img style="width: 20px" src="/assets/img/grey_close.svg" />
      </span>
    </v-btn>

    <v-row>
      <v-col cols="12" v-if="showFilters">
        <v-btn class="filterbtn" @click="filtrar"> Filtrar </v-btn>
        <v-btn class="limpar" @click="limpar"> Limpar </v-btn>
      </v-col>
    </v-row>

    <div
      v-if="showFilters"
      style="max-height: 60vh; overflow: hidden; overflow-y: auto"
      class="filtersSettings"
    >
      <div v-if="$store.state.uf == 'NA'">
        <!-- <div v-if="$store.state.uf === 'NA'"> -->
        <UF-combobox :filter="filter" />
        <color-picker
          v-if="filter.uf.length"
          :defaultColor="filtersColor.uf"
          @onChange="(event) => changeMapColor(event, 'uf')"
        ></color-picker>
      </div>
      <div v-if="$store.state.uf !== 'NA'">
        <app-ur-combobox :filter="filter" />
        <color-picker
          v-if="filter.ur.length"
          :defaultColor="filtersColor.region"
          @onChange="(event) => changeMapColor(event, 'region')"
        ></color-picker>
      </div>
      <div v-if="!isSala">
        <relacionamento-combobox
          :filter="filter"
          @changeRelacionamento="$emit('getByStatus')"
        />
        <color-picker
          v-if="filter.relacionamento.length"
          :defaultColor="filtersColor.relacionamento"
          @onChange="(event) => changeMapColor(event, 'relacionamento')"
        ></color-picker>
      </div>
      <div v-if="!isSala">
        <div class="eixo">
          <h1>{{ isSala ? "Pilares" : "Eixos" }}</h1>
          <div>
            <eixo-combobox
              :filter="filter"
              :disabled="!!filter.municipio.codigoIbge"
            />
          </div>
          <div class="mt-5">
            <v-select
              v-model="filter.eixoTipo"
              id="eixoTipo"
              color="primary"
              dense
              outlined
              :hide-details="true"
              click:prepend
              :items="['E', 'OU']"
              label="Tipo de filtragem"
            >
              <template v-slot:prepend>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon color="primary">mdi-information</v-icon></span
                    >
                  </template>
                  <span
                    >Diga o mecanismo de filtro para o
                    {{ isSala ? "Pilar" : "Eixo" }}. Filtros com E trará somente
                    {{ isSala ? "pilares" : "eixos" }} que possuem os dois.
                    Filtros com OU trará o que possui um ou outro.
                  </span>
                </v-tooltip>
              </template>
            </v-select>
          </div>
        </div>
        <color-picker
          v-if="filter.eixo.length"
          :defaultColor="filtersColor.eixo"
          @onChange="(event) => changeMapColor(event, 'eixo')"
        ></color-picker>
      </div>
      <div>
        <div :class="!isSala ? 'eixo mt-5' : ''" v-if="!isSala">
          <h1 v-if="!isSala">{{ isSala ? "Critérios" : "Indicadores" }}</h1>

          <div>
            <indicador-combobox
              :filter="filter"
              :disabled="!!filter.municipio.codigoIbge"
            />
          </div>
          <div v-if="!isSala">
            <v-select
              v-model="filter.indicadorTipo"
              id="eixoTipo"
              color="primary"
              dense
              outlined
              click:prepend
              :hide-details="true"
              :items="['E', 'OU']"
              label="Tipo de filtragem"
            >
              <template v-slot:prepend>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon color="primary">mdi-information</v-icon></span
                    >
                  </template>
                  <span
                    >Diga o mecanismo de filtro para o
                    {{ isSala ? "Critério" : "Indicador" }}. Filtros com E trará
                    somente {{ isSala ? "critérios" : "indicadores" }} que
                    possuem os dois. Filtros com OU trará o que possui um ou
                    outro.
                  </span>
                </v-tooltip>
              </template>
            </v-select>
          </div>
        </div>
        <color-picker
          v-if="filter.indicador.length"
          :defaultColor="filtersColor.indicador"
          @onChange="(event) => changeMapColor(event, 'indicador')"
        ></color-picker>
      </div>

      <div v-if="!isSala">
        <municipio-combobox
          :filter="filter"
          :disabled="filter.indicador.perguntaId > 0 || filter.eixo.length > 0"
        />
        <color-picker
          v-if="filter.municipio.codigoIbge"
          :defaultColor="filtersColor.municipio"
          @onChange="(event) => changeMapColor(event, 'municipio')"
        ></color-picker>
      </div>
    </div>
    <div>
      <slot name="downloads"></slot>
    </div>
  </div>
</template>
<script>
import { MunicipioCombobox, municipioAPI } from "@/modules/municipio";
import { UFCombobox } from "@/modules/uf";
import { RelacionamentoCombobox } from "@/modules/relacionamento";
import { ContratoCombobox } from "@/modules/contrato";
import { IndicadorCombobox } from "@/modules/game";
import { mapGetters, mapActions } from "vuex";
import { EixoCombobox } from "@/modules/eixo";
import { ColorPicker } from "@/components";
import { AppUrCombobox } from "@/modules/ur";
import {
  statusRelacionamentoEnum as relacionamentoEnum,
  statusRelacionamentoSeloEnum,
} from "@/types";

const filtroInicial = {
  ur: [],
  uf: [],
  eixo: [],
  relacionamento: [],
  contrato: [],
  municipio: {},
  indicador: [],
  eixoTipo: "OU",
  indicadorTipo: "OU",
};

export default {
  name: "AppRelacionamentoCombobox",
  components: {
    MunicipioCombobox,
    IndicadorCombobox,
    UFCombobox,
    RelacionamentoCombobox,
    ContratoCombobox,
    EixoCombobox,
    ColorPicker,
    AppUrCombobox,
  },
  computed: {
    ...mapGetters(["mapa", "isSala"]),
  },
  data() {
    return {
      showFilters: true,
      mapaRelacionamento: [],
      mapaUfs: [],
      filter: filtroInicial,
      filtersColor: {
        uf: "#3e2577",
        contrato: "#fdc70c",
        relacionamento: "#e93e3a",
        region: "#f3903f",
        indicador: "#257725",
        eixo: "#993399",
        municipio: "#002947",
      },
      auxMapa: [],
      qtdRelacionamento: 0,
      qtdMunicipio: 0,
      qtdUf: 0,
      qtdIndicador: 0,
      qtdEixos: 0,
      qtdUr: 0,
      mapaIndicador: [],
      mapaEixos: [],
    };
  },
  mounted() {
    if (localStorage.getItem("mapFiltersColors")) {
      this.filtersColor =
        JSON.parse(localStorage.getItem("mapFiltersColors")) ||
        this.filtersColor;
    }
  },
  methods: {
    ...mapActions([
      "setMapaVariables",
      "limparMapaFixo",
      "setLoading",
      "resetarMapaFixo",
    ]),
    changeMapColor(event, label) {
      this.filtersColor[label] = event;
      localStorage.setItem(
        "mapFiltersColors",
        JSON.stringify(this.filtersColor)
      );

      this.$emit("changeColor", this.filtersColor);
    },
    async getMapaEixos() {
      try {
        const { data: mapaEixos } = await municipioAPI.getMunicipiosByEixo(
          this.filter.eixo.map((item) => item.temaId).join(",")
        );

        this.mapaEixos = mapaEixos;

        if (!this.isSala && this.filter.relacionamento.length == 0)
          this.filter.relacionamento = [
            {
              statusId: relacionamentoEnum.vigente,
              statusNome: "Vigente",
            },
          ];
      } catch (error) {
        this.$notification.error("Erro ao carregar");
        this.setLoading(false);
      }
    },
    async getMapaIndicador() {
      try {
        const { data: mapaIndicadores } = await municipioAPI.getByIndicadores(
          this.filter.indicador.map((item) => item.perguntaId).join(","),
          null,
          null,
          this.filter.indicadorTipo
        );

        this.mapaIndicador = mapaIndicadores;
        if (this.filter.relacionamento.length == 0 && !this.isSala) {
          this.filter.relacionamento = [
            {
              statusId: relacionamentoEnum.vigente,
              statusNome: "Vigente",
            },
          ];
        }
      } catch (err) {
        this.setLoading(false);

        console.log("err: ", err);
        this.$notification.error("Erro ao carregar");
      }
    },
    filtrarPorRelacionamento(item) {
      if (this.filter.relacionamento.length > 0) {
        if (
          this.filter.relacionamento.some(
            (val) => val.statusId == item.statusId
          )
        ) {
          if (!this.isSala) {
            item.class = "corRelacionamento";
            this.qtdRelacionamento++;
            this.filterarPorEixos(item);
          } else {
            if (this.filter.uf.length > 0) {
              if (this.filter.uf.some((val) => val.uf == item.uf)) {
                item.class = "corUF";
                this.qtdUf++;

                this.filterarPorEixos(item);
              }
            } else {
              this.filterarPorEixos(item);
            }
          }
        }
      } else {
        this.filterarPorEixos(item);
      }
    },
    filtrarPorMunicipio(item) {
      if (this.filter.municipio.codigoIbge == item.codigoIBGE) {
        item.class = "corMunicipio";
        this.qtdMunicipio++;
      }
    },

    filtrarPorIndicadores(item) {
      if (this.filter.indicador.length > 0) {
        if (
          this.mapaIndicador.filter(
            (val) =>
              this.filter.indicador.every((i) =>
                val.indicadores.split(",").includes(i.perguntaId + "")
              ) && val.codigoIBGE == item.codigoIBGE
          ).length > 0 &&
          this.filter.indicadorTipo == "E"
        ) {
          item.class = "corIndicador";
          this.qtdIndicador++;
          this.filtrarPorMunicipio(item);
        } else if (
          this.mapaIndicador.filter((val) => val.codigoIBGE == item.codigoIBGE)
            .length > 0 &&
          this.filter.indicadorTipo == "OU"
        ) {
          item.class = "corIndicador";
          this.qtdIndicador++;
          this.filtrarPorMunicipio(item);
        }
      } else {
        this.filtrarPorMunicipio(item);
      }
    },
    filterarPorEixos(item) {
      if (this.filter.eixo.length > 0) {
        if (
          this.mapaEixos.filter(
            (val) =>
              this.filter.eixo.every((i) =>
                val.eixos.split(",").includes(i.temaId + "")
              ) && val.codigoIbge == item.codigoIBGE
          ).length > 0 &&
          this.filter.eixoTipo == "E"
        ) {
          item.class = "corEixo";
          this.qtdEixos++;
          this.filtrarPorIndicadores(item);
        } else if (
          this.mapaEixos.filter((val) => val.codigoIbge == item.codigoIBGE)
            .length > 0 &&
          this.filter.eixoTipo == "OU"
        ) {
          item.class = "corEixo";
          this.qtdEixos++;
          this.filtrarPorIndicadores(item);
        }
      } else {
        this.filtrarPorIndicadores(item);
      }
    },
    filtrarPorUr(item) {
      if (this.filter.ur.length) {
        if (
          this.filter.ur.some(
            (val) => val.id_unidade_regional == item.id_Unidade_Regional
          )
        ) {
          item.class = "corUR";
          this.qtdUr++;

          this.filtrarPorRelacionamento(item);
        }
      }
    },
    async filtrar() {
      if (
        this.filter.uf.length == 0 &&
        this.filter.indicador.length == 0 &&
        !this.filter.municipio.codigoIbge &&
        this.filter.ur.length == 0 &&
        this.filter.eixo.length == 0 &&
        this.filter.relacionamento.length == 0 &&
        this.filter.contrato.length == 0
      ) {
        return;
      }
      this.setLoading(true);

      this.resetarMapaFixo();
      if (this.isSala)
        this.filter.relacionamento = [
          {
            statusId: statusRelacionamentoSeloEnum.participante,
            statusNome: "Participante",
          },
        ];

      if (this.filter.indicador.length) {
        await this.getMapaIndicador();
      }

      if (this.filter.eixo.length) {
        await this.getMapaEixos();
      }

      this.auxMapa = this.mapa.mapaFixo;

      this.qtdRelacionamento = 0;
      this.qtdMunicipio = 0;
      this.qtdUf = 0;
      this.qtdIndicador = 0;
      this.qtdEixos = 0;

      this.auxMapa.forEach((item) => {
        if (this.isSala && item.desabilitado) {
          return item.desabilitado;
        } else if (this.filter.uf.length) {
          if (!this.isSala) {
            if (this.filter.uf.some((val) => val.uf == item.uf)) {
              item.class = "corUF";
              this.qtdUf++;

              this.filtrarPorRelacionamento(item);
            }
          } else {
            this.filtrarPorRelacionamento(item);
          }
        } else if (this.filter.ur.length) {
          this.filtrarPorUr(item);
        } else if (this.filter.relacionamento.length) {
          this.filtrarPorRelacionamento(item);
        } else if (this.filter.eixo.length) {
          this.filterarPorEixos(item);
        } else if (this.filter.indicador.length) {
          this.filtrarPorIndicadores(item);
        } else if (this.filter.municipio.codigoIbge) {
          this.filtrarPorMunicipio(item);
        }
      });

      this.setMapaVariables({
        mapaFixo: this.auxMapa,
        totalMapaIndicadores: null,
        totalMapaMunicipio: this.qtdMunicipio,
        totalMapaContratos: null,
        totalMapaRelacionamentos: this.qtdRelacionamento,
        totalMapaUfs: this.qtdUf,
        totalMapaIndicadores: this.qtdIndicador,
        totalMapaEixos: this.qtdEixos,
        totalMapaUrs: this.qtdUr,
        filter: {
          indicador: this.filter.indicador,
          contratos: [],
          relacionamentos: this.filter.relacionamento,
          municipio: this.filter.municipio,
          uf: this.filter.uf,
          eixo: this.filter.eixo,
          ur: this.filter.ur,
          eixoTipo: this.filter.eixoTipo,
          indicadorTipo: this.filter.indicadorTipo,
        },
      });

      this.setLoading(false);
    },
    limpar() {
      this.setLoading(true);
      this.limparMapaFixo();
      this.filter = {
        ur: [],
        uf: [],
        eixo: [],
        relacionamento: [],
        contrato: [],
        municipio: {},
        indicador: [],
        indicadorTipo: "OU",
        eixoTipo: "OU",
      };
      this.qtdRelacionamento = 0;
      this.qtdMunicipio = 0;
      this.qtdUf = 0;
      this.mapaEixos = [];
      this.mapaIndicador = [];

      this.setLoading(false);
    },
  },
};
</script>
<style lang="scss" scoped>
.eixo {
  position: relative;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.38);
  padding: 10px;
  h1 {
    position: absolute;
    color: rgb(0 0 0 / 41%);
    top: -13px;
    left: 10px;
    background: #fff;
    font-size: 16px;
    padding: 0 10px;
  }
}
.filters {
  position: fixed;
  padding: 20px 0 20px 25px;
  z-index: 2;
  width: 300px;
  &.large {
    .filtersSettings > div {
      width: 100%;
    }
  }
}
</style>
