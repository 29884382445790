export const statusRelacionamentoEnum = {
    semRelacionamento: 0,
    negociacao: 2,
    vigente: 4,
    encerrado: 5,
};


export const statusRelacionamentoSeloEnum = {
    naoParticipante: 0,
    participante: 3,
    encerrado: 6
};
