<template>
  <v-container>
    <v-row v-if="$store.state.uf != 'BA'">
      <v-col>
        <app-regiao-combobox
          ref="regiaoSelect"
          :filter="payload"
          :disabled="payload.municipio.municipioId > 0"
          :isMultiple="true"
          @changeRegiao="getByRegional(payload.regiao)"
        />
      </v-col>
      <v-col>
        <template>
          <v-select
            id="projeto-select"
            v-model="projetoId"
            :filter="payload"
            item-text="nome"
            item-name="projetoId"
            :items="projetos"
            label="Projeto"
            placeholder="Selecione um Projeto"
            :return-object="true"
            :disabled="payload.municipio.municipioId > 0"
            multiple
            @change="getByProjeto1(projetoId, payload.regiao.regiaoSebraeId)"
          >
            <template slot="no-data"> Nenhum Projeto retornado </template>
          </v-select>
        </template>
      </v-col>
      <v-col>
        <app-municipio-combobox
          ref="municipioSelect"
          :filter="payload"
          :disabled="
            payload.indicador.perguntaId > 0 ||
            payload.regiao.regiaoSebraeId > 0
          "
          @changeMunicipio="getByMunicipio(payload.municipio.codigoIBGE)"
          :isMultiple="true"
        />
      </v-col>
      <v-col cols="3" class="text-right">
        <v-btn color="accent" @click="limpar"> Limpar </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-select
          id="consultorselect"
          v-model="consultorid"
          item-text="nome"
          item-name="usuarioId"
          item-value="usuarioId"
          :items="consultores"
          label="Consultor"
          placeholder="Consultor"
          :clearable="true"
          :clear-search-on-select="false"
          :searchable="true"
          @change="$emit('onChange')"
          multiple
          :return-object="true"
        >
          <template slot="no-data"> Nada retornado </template>
        </v-select>
      </v-col>
      <v-col cols="4">
        <v-select
          id="solucaoselect"
          v-model="solucaoid"
          item-text="solucaonome"
          item-name="solucaoid"
          item-value="solucaoid"
          :items="solucoes"
          label="Soluções"
          placeholder="Soluções"
          :clearable="true"
          :clear-search-on-select="false"
          :searchable="true"
          @change="$emit('onChange')"
          multiple
          :return-object="true"
        >
          <template slot="item" slot-scope="data">
            {{ data.item.gruposolucaonome }} - {{ data.item.solucaonome }}
          </template>
          <template slot="no-data"> Nada retornado </template>
        </v-select>
      </v-col>
      <v-col cols="2">
        <v-btn color="accent" @click="getExecucao()"> Filtrar </v-btn>
      </v-col>
      <v-col cols="2">
        <v-checkbox v-model="agrupar" :label="`Agrupar Soluções`" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <table width="100%">
          <tr
            v-if="mapaRegional.length"
            style="background-color: #0489b1"
            align="center"
          >
            <td>
              <b>{{ mapaRegional.length }}</b> município(s) na Unidade Regional
              <b>{{ payload.regiao.nome }}</b>
            </td>
          </tr>
          <tr
            v-if="projetoId[0]"
            style="background-color: #5f9ea0"
            align="center"
          >
            <td>
              <b>{{ mapaProjeto.length }}</b> município(s) no(s) projeto(s)
              <span v-for="projeto in projetoId" :key="projeto.projetoId">
                <b>| {{ projeto.nome }}</b>
              </span>
            </td>
          </tr>
          <tr
            v-if="mapaIndicadores.length > 0 && consultorid && consultorid > 0"
            style="background-color: #04b486"
            align="center"
          >
            <td>
              <b>{{ mapaIndicadores.length }}</b> soluções com atuação do
              consultor <b>{{ mapaIndicadores[0].usuarioNome }}</b> nos
              municípios abaixo
            </td>
          </tr>
          <tr
            v-if="mapaIndicadores.length > 0 && solucaoid && solucaoid != 0"
            style="background-color: #04b486"
            align="center"
          >
            <td>
              <b>{{ mapaIndicadores.length }}</b> município(s) rodando a solução
              <b></b>
            </td>
          </tr>
        </table>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div _ngcontent-c21="">
          <div _ngcontent-c21="" class="mapa">
            <div _ngcontent-c21="">
              <svg
                _ngcontent-c21=""
                xmlns:xlink="http://www.w3.org/1999/xlink"
                preserveAspectRatio="xMidYMid meet"
                xmlns="http://www.w3.org/2000/svg"
                :viewBox="$store.state.svg"
                @mouseout="tooltipMap.disable = 'none'"
              >
                <!-- MAPA FIXO -->
                <g _ngcontent-c21="" class="uf" transform="scale(1 -1)">
                  <g
                    v-for="municipio in mapaFixo"
                    :key="municipio.municipioId"
                    _ngcontent-c21=""
                    vector-effect="non-scaling-stroke"
                    @mousemove="exibirMunicipiosNomes($event, municipio)"
                  >
                    <g
                      v-if="
                        mapaRegional.length === 0 &&
                        mapaProjeto.length === 0 &&
                        mapaIndicadores.length === 0
                      "
                      _ngcontent-c21=""
                      class="faixa1"
                      :codigo="municipio.codigoIBGE"
                      :nome="municipio.nome"
                      link=""
                    >
                      <g
                        @click="exibeDadosMunicipio(municipio)"
                        v-html="municipio.poligono"
                      />
                    </g>
                    <g
                      v-else
                      _ngcontent-c21=""
                      class="faixa1"
                      :codigo="municipio.codigoIBGE"
                      :nome="municipio.nome"
                      link=""
                      @mousemove="exibirMunicipiosNomes($event, municipio)"
                    >
                      <g v-html="municipio.poligono" />
                    </g>
                  </g>
                </g>

                <!-- MAPA MUNICÍPIO -->
                <g _ngcontent-c21="" class="uf" transform="scale(1 -1)">
                  <g
                    v-for="municipio in mapaMunicipio"
                    :key="municipio.municipioId"
                    _ngcontent-c21=""
                    vector-effect="non-scaling-stroke"
                    @mousemove="exibirMunicipiosNomes($event, municipio)"
                  >
                    <g
                      _ngcontent-c21=""
                      class="faixa2"
                      :codigo="municipio.codigoIBGE"
                      :nome="municipio.nome"
                      link=""
                    >
                      <g
                        @click="exibeDadosMunicipio(municipio)"
                        v-html="municipio.poligono"
                      />
                    </g>
                  </g>
                </g>

                <!-- MAPA VARIÁVEL REGIÃO-->
                <g
                  v-if="payload.regiao.regiaoSebraeId"
                  _ngcontent-c21=""
                  class="uf"
                  transform="scale(1 -1)"
                >
                  <g
                    v-for="municipio in mapaRegional"
                    :key="municipio.municipioId"
                    _ngcontent-c21=""
                    vector-effect="non-scaling-stroke"
                    @mousemove="exibirMunicipiosNomes($event, municipio)"
                  >
                    <g
                      _ngcontent-c21=""
                      class="faixa3"
                      :codigo="municipio.codigoIBGE"
                      :nome="municipio.nome"
                      link=""
                    >
                      <g
                        @click="exibeDadosMunicipio(municipio)"
                        v-html="municipio.poligono"
                      />
                    </g>
                  </g>
                </g>

                <!-- MAPA VARIÁVEL PROJETO-->
                <g
                  v-if="mapaProjeto.length != 0"
                  _ngcontent-c21=""
                  class="uf"
                  transform="scale(1 -1)"
                >
                  <g
                    v-for="municipio in mapaProjeto"
                    :key="municipio.municipioId"
                    _ngcontent-c21=""
                    vector-effect="non-scaling-stroke"
                    @mousemove="exibirMunicipiosNomes($event, municipio)"
                  >
                    <g
                      _ngcontent-c21=""
                      class="faixa2"
                      :codigo="municipio.codigoIBGE"
                      :nome="municipio.nome"
                      link=""
                    >
                      <g
                        @click="exibeDadosMunicipio(municipio)"
                        v-html="municipio.poligono"
                      />
                    </g>
                  </g>
                </g>

                <!-- MAPA DOS INDICADORES -->
                <g
                  v-if="mapaIndicadores.length > 0"
                  _ngcontent-c21=""
                  class="uf"
                  transform="scale(1 -1)"
                >
                  <g
                    v-for="municipio in mapaIndicadores"
                    :key="municipio.municipioId"
                    _ngcontent-c21=""
                    vector-effect="non-scaling-stroke"
                    @mousemove="exibirMunicipiosNomes($event, municipio)"
                  >
                    <g
                      _ngcontent-c21=""
                      class="faixa4"
                      :codigo="municipio.codigoIBGE"
                      :nome="municipio.nome"
                      link=""
                    >
                      <g
                        @click="exibeDadosMunicipio(municipio)"
                        v-html="municipio.poligono"
                      />
                    </g>
                  </g>
                </g>
              </svg>
              <div
                class="description"
                :style="{
                  top: tooltipMap.pageY,
                  left: tooltipMap.pageX,
                  display: tooltipMap.disable,
                  position: 'absolute',
                }"
              >
                {{ municipioName }}
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <app-modal-indicador-execucao
      :municipio="municipioSelecionado"
      :exibe-modal="exibeModal"
      :itens="[{ titulo: 'Dados Gerais', texto: '1' }]"
      @fecharModal="fecharModal"
      @closeModal="exibeModal = false"
    />

    <v-row v-if="mapaIndicadores.length > 0" class="text-center">
      <v-col class="text-center" cols="12" align="center">
        <v-data-table
          :headers="headers"
          :items="mapaIndicadores"
          class="elevation-1"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:item.Municipio="{ item }">
            <span>
              {{ item.nome }}
            </span>
          </template>
          <!--
          <template v-slot:item.acao="{ item }">
            <span>
              <a @click="exibeDadosMunicipio(item)">Detalhes</a>
            </span>
          </template>
          -->
          <template slot="no-data">
            <span> Selecione um filtro acima </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AppModalIndicadorExecucao from "./ModalIndicadorExecucao";
import AppMunicipioCombobox from "@/modules/municipio/components/Combobox";
import { municipioAPI } from "@/modules/municipio";

import { AppRegiaoCombobox } from "@/modules/regiao";

export default {
  components: {
    AppModalIndicadorExecucao,
    AppMunicipioCombobox,
    // AppProjetoCombobox,
    AppRegiaoCombobox,
  },
  computed: {
    ...mapGetters([
      "isLogged",
      "isAuthenticated",
      "isConsultor",
      "isAdmin",
      "loading",
      "user",
      "uf",
      "logo",
      "svg",
    ]),
  },
  data() {
    return {
      headers: [
        { text: "Município", value: "municipioNome" },
        { text: "Grupo", value: "municipioGrupoSolucaoNome" },
        { text: "Solução", value: "municipioSolucaoNome" },
        { text: "Responsável", value: "usuarioNome" },
        { text: "Horas Previstas", value: "horasPrevistas" },
        { text: "Horas Executadas", value: "horasExecutadas" },
        { text: "Execução", value: "execucao" },
        // { text: 'Detalhes', value: 'acao' }
      ],
      estado: this.uf,
      indicadores: [],
      indicadoresFiltrados: 0,
      mapaFixo: [],
      mapaMunicipio: [],
      mapaRegional: [],
      mapaProjeto: [],
      mapaIndicadores: [],
      regionalId: null,
      projetoId: [],
      municipios: [],
      projetos: [],
      municipiosCE: [],
      municipiosCE_filtrados: [],
      municipiosRegiao: [],
      municipiosProjeto: [],
      municipioSelecionado: {},
      municipiosFiltrados: 0,
      codigos: "",
      payload: {
        indicador: [],
        municipio: [],
        municipiosFiltrados: [],
        projeto: [],
        regiao: [],
        municipiosFiltradosIndicadores: [],
      },
      exibeModal: false,
      svgUF: this.svg,
      projetosFiltrados: 0,
      dadosExecucao: [],
      unidaderegionalIds: 0,
      projetoIds: 0,
      municipioids: 0,
      solucaoids: 0,
      usuarioids: 0,
      municipiosolucaoNome: 0,
      consultores: [],
      consultorid: 0,
      solucoes: [],
      solucaoid: 0,
      tooltipMap: {
        pageX: 10 + "px",
        pageY: 10 + "px",
        disable: "none",
      },
      municipioName: "",
      agrupar: false,
    };
  },
  methods: {
    ...mapActions([
      "setLoading",
      "setToken",
      "setUser",
      "setAdmin",
      "setExpired",
      "setUf",
      "setLogo",
      "setSvg",
    ]),
    exibeDadosMunicipio(municipio) {
      this.municipioSelecionado = municipio;
      // this.exibeModal = true
    },
    fecharModal() {
      this.exibeModal = false;
    },
    exibirMunicipiosNomes(e, municipio) {
      this.municipioName = municipio.nome;
      this.tooltipMap = {
        pageX: e.pageX + 10 + "px",
        pageY: e.pageY - 150 + "px",
        disable: "block",
      };
    },
    changeRegiao() {
      this.setLoading(true);
      // this.$refs.municipioSelect.filtrar()
      if (this.payload.regiao.regiaoSebraeId) {
        municipioAPI
          .getByRegiao(this.payload.regiao.regiaoSebraeId)
          .then((resp) => {
            this.municipiosRegiao = resp.data;
            this.$forceUpdate();
            if (this.payload.indicador.perguntaId) {
              this.changeIndicador();
            }
            this.setLoading(false);
          })
          .catch(() => {
            this.$notification.error("Erro ao listar regiões");
            this.setLoading(false);
          });
      } else {
        this.$refs.municipioSelect.filtrar();
        this.setLoading(false);
      }
    },
    async changeProjeto() {
      this.setLoading(true);
      if (this.payload.projeto.projetoId) {
        this.municipiosCE_filtrados = this.municipiosCE.filter((municipio) => {
          return municipio.projetoId === this.payload.projeto.projetoId;
        });
        this.codigos = "";
        for (var i = 0; i < this.municipiosCE_filtrados.length; i++) {
          if (this.codigos === "") {
            this.codigos = this.municipiosCE_filtrados[i].codigoIBGE;
          } else {
            this.codigos =
              this.codigos + "," + this.municipiosCE_filtrados[i].codigoIBGE;
          }
        }
        municipioAPI
          .getProjetos(this.codigos)
          .then((resp) => {
            this.municipiosProjeto = resp.data;
            this.$forceUpdate();
            this.setLoading(false);
          })
          .catch(() => {
            this.$notification.error("Erro ao filtrar Municípios");
            this.setLoading(false);
          });
      } else {
        this.$refs.municipioSelect.filtrar();
        this.setLoading(false);
      }
    },
    listarMunicipios() {
      municipioAPI
        .list(this.$store.state.uf)
        .then((resp) => {
          this.municipios = resp.data;
          this.$forceUpdate();
        })
        .catch(() => {
          this.$notification.error("Erro ao listar municípios");
        });
    },
    getConsultores() {
      municipioAPI
        .getConsultores()
        .then((resp) => {
          this.consultores = resp.data;
        })
        .catch(() => {
          this.$notification.error("Erro ao carregar consultores");
        });
    },
    getSolucoes() {
      municipioAPI
        .getSolucoes()
        .then((resp) => {
          this.solucoes = resp.data;
        })
        .catch(() => {
          this.$notification.error("Erro ao carregar soluções");
        });
    },
    getExecucao() {
      this.setLoading(true);
      var idsDoProjeto = 0;
      var idsDaRegiao = 0;
      var idsDoMunicipio = 0;
      var idsDaSolucao = 0;
      var idsDoConsultor = 0;
      var nomeDaSolucao = 0;
      this.mapaIndicadores = [];
      for (var i = 0; i < this.projetoId.length; i++) {
        idsDoProjeto = idsDoProjeto + "," + this.projetoId[i].projetoId;
      }
      if (this.payload.regiao.length > 0) {
        idsDaRegiao = this.payload.regiao
          .map((item) => item.regiaoSebraeId)
          .join(",");
      }
      if (this.solucaoid.length > 0) {
        idsDaSolucao = this.solucaoid.map((item) => item.solucaoid).join(",");
      }
      if (this.consultorid.length > 0) {
        idsDoConsultor = this.consultorid
          .map((item) => item.usuarioId)
          .join(",");
      }
      if (this.payload.municipio.length > 0) {
        idsDoMunicipio = this.payload.municipio
          .map((item) => item.codigoIbge)
          .join(",");
      }

      if (this.agrupar) {
        municipioAPI
          .getExecucaoAgrupada(
            idsDaRegiao,
            idsDoProjeto,
            idsDoMunicipio,
            idsDaSolucao,
            idsDoConsultor,
            nomeDaSolucao
          )
          .then((resp) => {
            this.dadosExecucao = resp.data;
            for (var i = 0; i < this.dadosExecucao.length; i++) {
              if (this.dadosExecucao[i].horasExecutadas > 0) {
                this.dadosExecucao[i].execucao =
                  parseFloat(
                    (this.dadosExecucao[i].horasExecutadas /
                      this.dadosExecucao[i].horasPrevistas) *
                      100
                  ).toFixed(2) + "%";
              } else {
                this.dadosExecucao[i].execucao = "0%";
              }
            }
            this.mapaIndicadores = resp.data;
            this.setLoading(false);
          })
          .catch(() => {
            this.$notification.error("Erro ao carregar");
            this.setLoading(false);
          });
      } else {
        municipioAPI
          .getExecucao(
            idsDaRegiao,
            idsDoProjeto,
            idsDoMunicipio,
            idsDaSolucao,
            idsDoConsultor,
            nomeDaSolucao
          )
          .then((resp) => {
            this.dadosExecucao = resp.data;
            for (var i = 0; i < this.dadosExecucao.length; i++) {
              if (this.dadosExecucao[i].horasExecutadas > 0) {
                this.dadosExecucao[i].execucao =
                  parseFloat(
                    (this.dadosExecucao[i].horasExecutadas /
                      this.dadosExecucao[i].horasPrevistas) *
                      100
                  ).toFixed(2) + "%";
              } else {
                this.dadosExecucao[i].execucao = "0%";
              }
            }
            this.mapaIndicadores = resp.data;
            this.setLoading(false);
          })
          .catch(() => {
            this.$notification.error("Erro ao carregar");
            this.setLoading(false);
          });
      }
    },
    getMunicipiosCE() {
      municipioAPI
        .getMunicipiosCE(this.$store.state.uf)
        .then((resp) => {
          this.municipiosCE = resp.data;
          for (var i = 0; i < this.municipiosCE.length; i++) {
            // this.projetos.push({ 'projetoId': this.municipiosCE[i].projeto.projetoId, 'nome': this.municipiosCE[i].projeto.nome })
          }
          this.$forceUpdate();
        })
        .catch(() => {
          this.$notification.error("Erro ao listar municípios");
        });
    },
    limpar() {
      this.payload.municipio = [];
      this.payload.projeto = [];
      this.payload.regiao = [];
      this.payload.indicador = {};
      this.payload.municipiosFiltradosIndicadores = [];
      this.payload.municipiosFiltrados = [];
      this.municipiosRegiao = [];
      this.projetoId = [];
      this.mapaProjeto = [];
      this.mapaIndicadores = [];
      this.mapaRegional = [];
      this.mapaMunicipio = [];
      this.subGrupoSelecionado = false;
      this.solucaoid = 0;
      this.consultorid = 0;
    },
    getMapaFixo() {
      this.setLoading(true);
      municipioAPI
        .getMapaFixo()
        .then((resp) => {
          this.mapaFixo = resp.data;
          this.setLoading(false);
        })
        .catch(() => {
          this.$notification.error("Erro ao carregar");
          this.setLoading(false);
        });
    },
    getByMunicipio(codigoIBGE) {
      this.mapaMunicipio = this.mapaFixo.filter((m) => {
        return m.codigoIBGE === codigoIBGE;
      });
    },
    getProjetosTodos() {
      municipioAPI
        .getProjetosTodos()
        .then((resp) => {
          this.projetos = resp.data;
          this.setLoading(false);
        })
        .catch(() => {
          this.$notification.error("Erro ao carregar");
          this.setLoading(false);
        });
    },
    getByRegional(id) {
      this.setLoading(true);
      this.payload.indicador = {};
      this.mapaIndicadores = [];
      const ids = id.map((item) => item.regiaoSebraeId).join(",");
      municipioAPI
        .getByRegional(ids)
        .then((resp) => {
          this.mapaRegional = resp.data;
          this.setLoading(false);
        })
        .catch(() => {
          this.$notification.error("Erro ao carregar");
          this.setLoading(false);
        });
      if (this.projetoId.length > 0) {
        this.getByProjeto1(this.projetoId, id);
      }
    },
    getByProjeto1(projetoIds, regionalId) {
      this.setLoading(true);
      this.payload.indicador = {};
      this.mapaIndicadores = [];
      if (projetoIds.length > 0) {
        var projetoIdsString = "";
        for (var i = 0; i < projetoIds.length; i++) {
          if (projetoIdsString === "") {
            projetoIdsString = projetoIds[i].projetoId;
          } else {
            projetoIdsString = projetoIdsString + "," + projetoIds[i].projetoId;
          }
        }
        if (regionalId === undefined) {
          regionalId = null;
        }
        municipioAPI
          .getByProjeto1(projetoIdsString, regionalId)
          .then((resp) => {
            this.mapaProjeto = resp.data;
            this.setLoading(false);
          })
          .catch(() => {
            this.$notification.error("Erro ao carregar");
            this.setLoading(false);
          });
      } else {
        this.mapaProjeto = [];
        this.setLoading(false);
      }
    },
  },
  mounted() {
    this.getMapaFixo();
    this.listarMunicipios();
    this.getMunicipiosCE();
    this.getConsultores();
    this.getSolucoes();
    this.getProjetosTodos();
    // this.getExecucao(this.unidaderegionalIds, this.projetoIds, this.municipioids, this.solucaoids, this.usuarioids, this.municipiosolucaoNome)
    // this.getExecucao()
  },
};
</script>

<style lang="scss">
.description {
  pointer-events: none;
  position: absolute;
  font-size: 18px;
  text-align: center;
  background: white;
  padding: 10px 15px;
  z-index: 5;
  line-height: 30px;
  margin: 0 auto;
  color: #21669e;
  border-radius: 5px;
  box-shadow: 0 0 0 1px #eee;
  transform: translateX(-50%);

  &.active {
    display: block;
  }
  &:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 100%;
    width: 0;
    height: 0;
    margin-left: -10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
  }
}
svg[_ngcontent-c21],
svg[_ngcontent-c22] {
  -ms-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}

.target {
  fill: none;
  pointer-events: visibleFill;
}
svg:hover g:not(:hover) text {
  opacity: 0;
}

.container[_ngcontent-c21],
.container[_ngcontent-c22] {
  overflow: hidden;
}

.mapa[_ngcontent-c21],
.mapa[_ngcontent-c22] {
  width: 100%;
  margin-top: 0px;
}

.alturafixa_legenda[_ngcontent-c21],
.alturafixa_legenda[_ngcontent-c22] {
  height: 50px;
  margin-top: 0px;
}

.ie10[_ngcontent-c21] .mapa[_ngcontent-c21] svg[_ngcontent-c21],
.ie10[_ngcontent-c22] .mapa[_ngcontent-c22] svg[_ngcontent-c22] {
  height: 600px;
}

@media all and (-ms-high-contrast: none) {
  .mapa[_ngcontent-c21] svg[_ngcontent-c21],
  .mapa[_ngcontent-c22] svg[_ngcontent-c22] {
    height: 600px;
  }
}

.mapa__label--mun-selecionado[_ngcontent-c21],
.mapa__label--mun-selecionado[_ngcontent-c22] {
  width: 100%;
  padding-bottom: 3px;
}

.mapa__label--mun-selecionado[_ngcontent-c21] .tip[_ngcontent-c21],
.mapa__label--mun-selecionado[_ngcontent-c22] .tip[_ngcontent-c22] {
  font-size: 0.7em;
  padding: 0;
}

#faixas[_ngcontent-c21],
#faixas[_ngcontent-c22] {
  padding: 0 0 7px 5%;
}
#faixas[_ngcontent-c21] h4[_ngcontent-c21],
#faixas[_ngcontent-c22] h4[_ngcontent-c22] {
  font-size: 0.85em;
  color: #fff;
  margin: 0 0 5px 0;
  padding: 5px;
  text-align: center;
  background-color: #888;
}
.txt-faixa[_ngcontent-c21],
.txt-faixa[_ngcontent-c22] {
  font-size: 0.82em;
  line-height: 23px;
  margin-left: 5px;
  vertical-align: bottom;
}
.ico-faixa[_ngcontent-c21],
.ico-faixa[_ngcontent-c22] {
  display: inline-block;
  border: 1px solid #aaa;
  width: 20px;
  height: 12px;
  margin-left: 10px;
}
.seletor-periodos[_ngcontent-c21],
.seletor-periodos[_ngcontent-c22] {
  text-align: center;
}
.periodo-link[_ngcontent-c21]:after,
.periodo-link[_ngcontent-c22]:after {
  content: " /";
}

g.faixa1[_ngcontent-c21] polygon[_ngcontent-c21] {
  fill: #81bef7;
}
g.faixa2[_ngcontent-c21] polygon[_ngcontent-c21] {
  fill: #5f9ea0;
}
g.faixa4[_ngcontent-c21] polygon[_ngcontent-c21] {
  fill: #04b486;
}
g.faixa6[_ngcontent-c21] polygon[_ngcontent-c21] {
  fill: #04b486;
}

g.faixa1[_ngcontent-c22] polygon[_ngcontent-c22] {
  fill: #81bef7;
}
g.faixa2[_ngcontent-c22] polygon[_ngcontent-c22] {
  fill: #5f9ea0;
}
g.faixa4[_ngcontent-c22] polygon[_ngcontent-c22] {
  fill: #04b486;
}
g.faixa6[_ngcontent-c22] polygon[_ngcontent-c22] {
  fill: #04b486;
}

polygon[_ngcontent-c21],
polygon[_ngcontent-c22] {
  stroke: #fff;
  stroke-width: 0.01px;
  cursor: default;
  -webkit-transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  transition: all 700ms ease;
}
g.checked[_ngcontent-c21] polygon[_ngcontent-c21],
g.checked[_ngcontent-c22] polygon[_ngcontent-c22] {
  stroke: #fff;
  stroke-width: 0.01px;
  fill: #0d4768;
}
polygon[_ngcontent-c21]:hover,
polygon[_ngcontent-c22]:hover {
  fill-opacity: 0.5;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

g[_ngcontent-c21],
.periodo-link[_ngcontent-c21] {
  cursor: pointer;
}
g[_ngcontent-c22],
.periodo-link[_ngcontent-c22] {
  cursor: pointer;
}
</style>
