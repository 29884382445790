<template>
  <v-container>
    <v-dialog v-model="exibeModal" scrollable>
      <v-card>
        <v-card-title class="modal-header elevation-10">
          <v-col>
            <slot name="title"> {{ title }} </slot>
          </v-col>
          <v-col class="text-right">
            <v-icon color="white" @click="$emit('fecharRelatorio')">
              mdi-window-close
            </v-icon>
          </v-col>
        </v-card-title>

        <v-card-text>
          <v-row class="rowRelatorio">
            <v-col>
              <v-data-table
                :headers="header"
                :items="values"
                class="elevation-1"
                :items-per-page="5"
                :footer-props="{ itemsPerPageText: 'Itens por página' }"
                :search="search"
              >
                <template v-slot:top>
                  <v-text-field
                    v-model="search"
                    label="Pesquise..."
                    class="mx-4"
                  ></v-text-field>
                </template>
                <template slot="no-data">
                  <div v-if="!loading">
                    Esse relatório ainda não possui dados
                  </div>
                  <div v-else>Carregando...</div>
                </template>

                <template v-slot:item="{ item }">
                  <tr>
                    <td
                      class="text-xs-right pa-4"
                      v-for="(value, index) in Object.values(item)"
                      :key="index"
                    >
                      <a
                        :href="value"
                        target="_blank"
                        v-if="value && value.toString().startsWith('http')"
                      >
                        <v-icon color="primary">mdi-paperclip</v-icon>
                      </a>
                      <template v-else>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              {{ diminuirValue(transformValue(value)) }}</span
                            >
                          </template>
                          <span>{{ transformValue(value) }} </span>
                        </v-tooltip>
                      </template>
                    </td>
                  </tr>
                </template>
                <template v-slot:footer.page-text="items">
                  {{ items.pageStart }} - {{ items.pageStop }} de
                  {{ items.itemsLength }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="flex-grow-1"></div>
          <v-btn color="error" outlined text @click="$emit('fecharRelatorio')"
            >Fechar</v-btn
          >
          <v-btn color="info" outlined text @click="gerarRelatorio"
            >Download</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { municipioAPI } from "@/modules/municipio";
import moment from "moment";

export default {
  props: {
    tipoRelatorio: { type: String, required: true },
    uf: { type: String, required: true },
    exibeModal: { type: Boolean, required: true },
  },
  data() {
    return {
      cabecalhoCriterios: [
        { text: "UF", value: "uf" },
        {
          text: "Sala Critérios Respondidos",
          value: "salaCriteriosRespondidos",
        },
      ],
      cabecalhoEixos: [
        { text: "UF", value: "uf" },
        { text: "Parceiro", value: "municipio" },
      ],
      cabecalhoIndicador: [
        { text: "UF", value: "uf" },
        { text: "Parceiro", value: "municipio" },

        { text: "Pilar", value: "eixo" },
        { text: "Criterio", value: "indicador" },

        { text: "Resposta", value: "resposta" },
        { text: "Pontos Obtidos", value: "pontos_Obtidos" },
        { text: "Projeto", value: "nomeProjeto" },
      ],
      quantidadeSeloAtribuidos: [
        { text: "UF", value: "uf" },
        { text: "Diamante", value: "diamante" },

        { text: "Ouro", value: "ouro" },
        { text: "Prata", value: "prata" },
        { text: "Bronze", value: "bronze" },
        { text: "Sem Selo", value: "semSelo" },
      ],
      cabecalhoSituacaoGeral: [
        { text: "UF", value: "uf" },
        { text: "Pilar", value: "pilar" },
        { text: "Respostas Sim", value: "respostasSim" },
        { text: "Criterios Evidenciados", value: "criteriosEvidenciados" },
        { text: "Aprovados", value: "aprovados" },
        { text: "Rejeitados", value: "rejeitados" },
        { text: "Aguardando Análise", value: "aguardandoAnalise" },
      ],
      header: [],
      values: [],
      search: "",
      title: "",
      regexDateWithT:
        /^(19|20)\d\d-(0[1-9]|1[012])-([012]\d|3[01])T([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/,

      regexDate:
        /^\d{4}[\-\/\s]?((((0[13578])|(1[02]))[\-\/\s]?(([0-2][0-9])|(3[01])))|(((0[469])|(11))[\-\/\s]?(([0-2][0-9])|(30)))|(02[\-\/\s]?[0-2][0-9]))$/,
      regexDateWithHour:
        /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/,
    };
  },
  computed: {
    ...mapGetters(["loading", "mapa"]),
    filter() {
      return {
        uf:
          (this.mapa.filter.uf &&
            this.mapa.filter.uf
              .filter((item) => !item.headers)
              .map((a) => `'${a.uf}'`)
              .join(",")) ||
          ufNA,
        statusId:
          (this.mapa.filter.relacionamentos &&
            this.mapa.filter.relacionamentos
              .map((item) => item.statusId)
              .join(",")) ||
          null,
        statusContratoId:
          (this.mapa.filter.contrato &&
            this.mapa.filter.contrato
              .map((item) => item.statusContratoId)
              .join(",")) ||
          null,
        eixoId:
          (this.mapa.filter.eixo.length &&
            this.mapa.filter.eixo.map((item) => item.temaId).join(",")) ||
          null,
        eixoTipo: this.mapa.filter.eixoTipo,
      };
    },
  },
  watch: {
    exibeModal: function (value) {
      if (value) {
        this.values = [];
        this.header = [];
        this.title = "";
        this.setLoading(true);
        this[this.tipoRelatorio]();
      }
    },
  },
  methods: {
    ...mapActions(["setLoading"]),
    transformValue(item) {
      if (typeof item === "boolean") {
        if (item) return "Sim";
        return "Não";
      } else if (
        this.regexDate.test(item) ||
        this.regexDateWithHour.test(item) ||
        this.regexDateWithT.test(item)
      ) {
        return moment(item).format("DD/MM/YYYY");
      }

      return item;
    },
    diminuirValue(item) {
      if (item && item.length > 50) {
        return item.substring(0, 50) + "...";
      }

      return item;
    },
    getRelatorioEixosSelo() {
      municipioAPI
        .getRelatorioEixosSelo(this.filter)
        .then(({ data: dadosrelatorio }) => {
          this.setLoading(false);
          this.title = "Relatório - Parceiros por UF";

          this.header = this.cabecalhoEixos;

          this.values = this.manipularDadosRelatorio(dadosrelatorio);
        })
        .catch((err) => {
          console.log("err getRelatorioEixosSelo: ", err);

          this.setLoading(false);
        });
    },
    getRelatorioIndicadorSelo() {
      municipioAPI
        .getRelatorioIndicadorSelo(this.filter)
        .then(({ data: dadosrelatorio }) => {
          this.setLoading(false);
          this.title = "Critérios por parceiro e UF";

          this.header = this.cabecalhoIndicador;

          this.values = this.manipularDadosRelatorio(dadosrelatorio);
        })
        .catch((err) => {
          console.log("err getRelatorioEixosSelo: ", err);

          this.setLoading(false);
        });
    },
    getRelatorioSituacaoGeral() {
      municipioAPI
        .getRelatorioSituacaoGeral(this.uf)
        .then(({ data: dadosrelatorio }) => {
          this.setLoading(false);
          this.title = "Relatório - Situação geral das evidencias enviadas";

          this.header = this.cabecalhoSituacaoGeral;

          this.values = this.manipularDadosRelatorio(dadosrelatorio);
        })
        .catch((err) => {
          console.log("err getRelatorioSituacaoGeral: ", err);

          this.setLoading(false);
        });
    },
    getQuantidadeSelosAtribuidos() {
      municipioAPI
        .getQuantidadeSelosAtribuidos(this.uf)
        .then(({ data: dadosrelatorio }) => {
          this.title = "Relatório - Quantitativo de Selos por UF";

          this.header = this.quantidadeSeloAtribuidos;

          this.values = this.manipularDadosRelatorio(dadosrelatorio);
          this.setLoading(false);
        })
        .catch((err) => {
          console.log("err getQuantidadeSelosAtribuidos: ", err);
          this.setLoading(false);
        });
    },
    gerarQuantidadeParceiros() {
      municipioAPI
        .gerarQuantidadeParceiros(this.filtroParceiros)
        .then(({ data: dadosrelatorio }) => {
          this.title = "Relatório - Quantitativo de Selos por UF";

          this.header = this.quantidadeSeloAtribuidos;

          this.values = this.manipularDadosRelatorio(dadosrelatorio);
          this.setLoading(false);
        })
        .catch((err) => {
          console.log("err getQuantidadeSelosAtribuidos: ", err);
          this.setLoading(false);
        });
    },
    getRelatorioParceirosCriterios() {
      municipioAPI
        .getRelatorioParceirosCriterios(this.uf)
        .then(({ data: dadosrelatorio }) => {
          this.title = "Relatório - Critérios respondidos";

          this.header = this.cabecalhoCriterios;

          this.values = this.manipularDadosRelatorio(dadosrelatorio);
          this.setLoading(false);
        })
        .catch((err) => {
          console.log("err getRelatorioParceirosCriterios: ", err);
          this.setLoading(false);
        });
    },
    manipularDadosRelatorio(relatorio) {
      return relatorio.map((d) => {
        var newObject = {};
        this.header.forEach((item) => {
          newObject = { ...newObject, [item.value]: d[item.value] };
        });
        return newObject;
      });
    },
    gerarRelatorio() {
      this.setLoading(true);

      if (this.tipoRelatorio == "getRelatorioSituacaoGeral") {
        municipioAPI
          .gerarRelatoriogSituacaoGeral(this.uf)
          .then(({ data: dados }) => {
            this.fazerDownloadRelatorio(
              dados,
              "Situação geral das evidencias enviadas"
            );
            this.setLoading(false);
          })
          .catch((erro) => {
            this.setLoading(false);

            console.log("Erro no gerarRelatorio do indicadoresGame ", erro);
            this.$notification.error("Erro ao gerar o relatório", { timer: 5 });
          });
      } else if (this.tipoRelatorio == "getRelatorioEixosSelo") {
        municipioAPI
          .gerarXlsx(this.filter)
          .then(({ data: dados }) => {
            this.fazerDownloadRelatorio(dados, "Parceiros por UF");
            this.setLoading(false);
          })
          .catch((erro) => {
            this.setLoading(false);

            console.log("Erro no gerarRelatorio do indicadoresGame ", erro);
            this.$notification.error("Erro ao gerar o relatório", { timer: 5 });
          });
      } else if (this.tipoRelatorio == "getRelatorioIndicadorSelo") {
        municipioAPI
          .gerarXlsxIndicador(this.filter)
          .then(({ data: dados }) => {
            this.fazerDownloadRelatorio(dados, "Critérios por parceiro e UF");
            this.setLoading(false);
          })
          .catch((erro) => {
            this.setLoading(false);

            console.log("Erro no gerarRelatorio do indicadoresGame ", erro);
            this.$notification.error("Erro ao gerar o relatório", { timer: 5 });
          });
      } else if (this.tipoRelatorio == "getQuantidadeSelosAtribuidos") {
        municipioAPI
          .gerarRelatorioQuantidadeSeloAtribuidos(this.uf)
          .then(({ data: dados }) => {
            this.fazerDownloadRelatorio(dados, "Quantitativo de Selos por UF");
            this.setLoading(false);
          })
          .catch((erro) => {
            this.setLoading(false);

            console.log("Erro no gerarRelatorio do indicadoresGame ", erro);
            this.$notification.error("Erro ao gerar o relatório", { timer: 5 });
          });
      } else if (this.tipoRelatorio == "gerarQuantidadeParceiros") {
        municipioAPI
          .gerarQuantidadeParceiros(this.filtroParceiros)
          .then(({ data: dados }) => {
            this.fazerDownloadRelatorio(
              dados,
              "Quantidades de salas por UF, Pilar e Faixa de Pontuação"
            );
            this.setLoading(false);
          })
          .catch((erro) => {
            this.setLoading(false);

            console.log("Erro no gerarRelatorio do indicadoresGame ", erro);
            this.$notification.error("Erro ao gerar o relatório", { timer: 5 });
          });
      } else {
        municipioAPI
          .gerarRelatorioParceirosCriterios(this.uf)
          .then(({ data: dados }) => {
            this.fazerDownloadRelatorio(dados, "Critérios respondidos");
            this.setLoading(false);
          })
          .catch((erro) => {
            this.setLoading(false);

            console.log("Erro no gerarRelatorio do indicadoresGame ", erro);
            this.$notification.error("Erro ao gerar o relatório", { timer: 5 });
          });
      }
    },
    fazerDownloadRelatorio(file, nomeArquivo) {
      var fileURL = window.URL.createObjectURL(new Blob([file]));
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `${nomeArquivo} - ${moment().format("DD_MM_YYYY_HH_mm")}.xlsx`
      );

      document.body.appendChild(fileLink);
      fileLink.click();
    },
  },
};
</script>

<style lang="scss">
.v-tabs-slider-wrapper {
  width: 0% !important;
}
.rowRelatorio {
  margin-left: -24px;
  margin-right: -24px;
  margin-top: 20px;
  color: #fff;
  background-color: #00000026;
}
</style>

<style lang="scss">
.v-data-table__wrapper {
  max-height: 320px;
  overflow-y: auto;
}
</style>
