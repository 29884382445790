<template>
  <v-container>
    <v-row>
      <v-col>
        Gestão do cadastro de Indicadores
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          id="select"
          v-model="filter.indicadorGrupoId"
          item-text="nome"
          item-name="indicadorGrupoId"
          item-value="indicadorGrupoId"
          :items="grupoIndicador"
          label="Grupo de Indicadores"
          placeholder="Grupo de Indicadores"
          @change="loadSubGrupos()"
        >
          <template slot="no-data">
            Nada retornado
          </template>
        </v-select>
      </v-col>
      <v-col>
        <v-select
          id="select"
          v-model="filter.indicadorSubgrupoId"
          item-text="nome"
          item-name="indicadorSubgrupoId"
          item-value="indicadorSubgrupoId"
          :items="subGrupoIndicador"
          label="SubGrupo"
          placeholder="SubGrupo"
          @change="loadIndicadores()"
        >
          <template slot="no-data">
            Nada retornado
          </template>
        </v-select>
      </v-col>
      <v-col>
        <v-select
          id="select"
          ref="solutionindicador"
          v-model="filter.indicadorId"
          item-text="nome"
          item-name="indicadorId"
          item-value="indicadorId"
          :items="indicador"
          label="Indicador"
          placeholder="Indicador"
          @change="$emit('onChange')"
        >
          <template slot="no-data">
            Nada retornado
          </template>
        </v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-tabs vertical>
        <v-tab>
          <v-icon left>
            mdi-domain
          </v-icon>
          Grupos
        </v-tab>
        <v-tab>
          <v-icon left>
            mdi-axis-arrow
          </v-icon>
          SubGrupos
        </v-tab>
        <v-tab>
          <v-icon left>
            mdi-beaker-outline
          </v-icon>
          Indicadores
        </v-tab>

        <v-tab-item>
          <v-col>
            <app-grupo-indicador />
          </v-col>
        </v-tab-item>
        <v-tab-item>
          <v-col>
            <app-sub-grupo-indicador
              ref="solutionList"
              :filter="filter"
              :sub-grupos="subGrupoIndicador"
              :grupo="filter.indicadorGrupoId"
              @reload="loadSubGrupos()"
            />
          </v-col>
        </v-tab-item>
        <v-tab-item>
          <v-col>
            <app-indicador
              ref="solutionList"
              :filter="filter"
              :indicadores="indicador"
              :subgrupo="filter.indicadorSubgrupoId"
              @reload="loadIndicadores()"
            />
          </v-col>
        </v-tab-item>
      </v-tabs>
    </v-row>
  </v-container>
</template>

<script>
// import { indicadorAPI } from '@/modules/indicador'
import { AppIndicador } from '@/modules/indicadores/indicador'
import { AppGrupoIndicador } from '@/modules/indicadores/grupoIndicador'
import { AppSubGrupoIndicador } from '@/modules/indicadores/subGrupoIndicador'
import { subGrupoIndicadorAPI } from '@/modules/indicadores/subGrupoIndicador/API/subGrupoIndicadorAPI'
import { indicadorAPI } from '@/modules/indicadores/indicador/API/indicadorAPI'
import { grupoIndicadorAPI } from '@/modules/indicadores/grupoIndicador/API/grupoIndicadorAPI'
import { mapActions, mapGetters } from 'vuex'

export default ({
  components: {
    AppGrupoIndicador,
    AppSubGrupoIndicador,
    AppIndicador
  },
  data () {
    return {
      filter: {
        indicadorGrupoId: 0,
        indicadorSubgrupoId: 0,
        indicadorId: 0,
        grupoIndicador: [],
        subGrupoIndicador: [],
        Indicador: []
      },
      subGrupoIndicador: [],
      grupoIndicador: [],
      indicador: [],
      estado: this.uf
    }
  },
  computed: {
    ...mapGetters([
      'isLogged',
      'isAuthenticated',
      'isConsultor',
      'isAdmin',
      'loading',
      'user',
      'uf',
      'logo',
      'svg'
    ])
  },
  methods: {
    ...mapActions(['setToken', 'setUser', 'setAdmin', 'setExpired', 'setUf', 'setLogo', 'setSvg']),
    loadIndicadores () {
      indicadorAPI.list()
        .then(resp => {
          this.indicador = resp.data.filter((i) => {
            return i.indicadorSubgrupoId === this.filter.indicadorSubgrupoId
          })
          this.filter.indicador = resp.data
        })
        .catch(() => {
          this.$notification.error('Erro ao listar')
        })
    },
    loadSubGrupos () {
      subGrupoIndicadorAPI.list()
        .then(resp => {
          this.clear()
          this.subGrupoIndicador = resp.data.filter((i) => {
            return i.indicadorGrupoId === this.filter.indicadorGrupoId
          })
          this.filter.subGrupoIndicador = resp.data
        })
        .catch(() => {
          this.$notification.error('Erro ao listar')
        })
    },
    loadGrupos () {
      grupoIndicadorAPI.list()
        .then(resp => {
          this.grupoIndicador = resp.data
          this.filter.grupoIndicador = resp.data
        })
        .catch(() => {
          this.$notification.error('Erro ao listar')
        })
    },
    clear () {
      this.indicador = []
      this.$refs['solutionindicador'].reset()
    }

  },
  mounted () {
    this.loadGrupos()
  }
})
</script>

<style lang="scss">
</style>
