<template>
  <div class="wrapper">
    <div
      :style="{ '--color': color }"
      class="colorTrigger"
      @click="open = !open"
    ></div>
    <div class="date-picker" @mouseleave="open = false" v-if="open">
      <v-color-picker
        v-model="color"
        show-swatches
        :swatches="swatches"
        @input="$emit('onChange', color)"
        mode="hexa"
      >
      </v-color-picker>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppColorPicker",
  props: {
    defaultColor: { type: String, default: () => {} },
  },
  watch: {
    defaultColor: {
      handler: function (newValue) {
        this.color = newValue;
      },
      deep: true,
    },
  },
  mounted() {
    this.color = this.defaultColor;
  },
  data() {
    return {
      color: { hex: this.defaultColor },
      open: false,
      swatches: [
        ["#FF0000", "#AA0000", "#550000"],
        ["#FFFF00", "#AAAA00", "#555500"],
        ["#00FF00", "#00AA00", "#005500"],
        ["#00FFFF", "#00AAAA", "#005555"],
        ["#0000FF", "#0000AA", "#000055"],
      ],
    };
  },
};
</script>
<style lang="scss">
.wrapper {
  //position: absolute;
  right: 19px;
  .colorTrigger {
    background: var(--color);
    display: block;
    height: 30px;
    width: 30px;
    cursor: pointer;
    border: 1px solid black;
  }
  .date-picker {
    position: absolute;
    top: 0;
    right: -134px;
    background: #fff;
    z-index: 2;
  }
}
</style>
