<template>
  <v-select
    id="eixo-select"
    v-model="filter.eixo"
    item-text="nomeTema"
    item-value="nomeTema"
    :items="eixos"
    :label="isSala ? 'Pilar' : 'Eixo'"
    placeholder="Selecione um Eixo"
    :return-object="true"
    :disabled="disabled"
    multiple
    @change="emitValue"
    solo
    hide-details="auto"
  >
    <div
      style="display: flex; padding: 0 16px; cursor: pointer"
      slot="prepend-item"
      ripple
      @click="toggle"
    >
      <div style="margin-right: 32px">
        <v-icon :color="filter.eixo.length > 0 ? 'indigo darken-4' : ''">{{
          icon
        }}</v-icon>
      </div>
      <div>Todos</div>
    </div>

    <v-divider slot="prepend-item" class="mt-2" />

    <template v-slot:selection="{ item, index }">
      <v-chip v-bind="item" small v-if="index < maxDisplay">
        <span>{{ diminuirValue(item.nomeTema) }}</span>
      </v-chip>
      <span v-if="index === maxDisplay" class="grey--text caption"
        >(+{{ filter.eixo.length - maxDisplay }} eixos)</span
      >
    </template>

    <template slot="no-data"> Nenhum Eixo retornado </template>
  </v-select>
</template>

<script>
import { municipioAPI } from "@/modules/municipio";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AppEixoCombobox",
  props: {
    filter: { type: Object, required: true },
    disabled: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      eixos: [],
      maxDisplay: 1,
    };
  },
  computed: {
    likesAll() {
      return this.filter.eixo.length === this.eixos.length;
    },
    likesSome() {
      return this.filter.eixo.length > 0 && !this.eixos;
    },
    icon() {
      if (this.likesAll) return "mdi-close-box";
      if (this.likesSome) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    ...mapGetters(["isSala"]),
  },
  mounted() {
    this.list();
  },
  methods: {
    diminuirValue(item) {
      if (item.length > 15) {
        return item.substring(0, 15) + "...";
      }

      return item;
    },
    emitValue(e) {
      this.$emit("changeEixo", e);
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAll) {
          this.filter.eixo = [];
          this.$emit("changeEixo", this.filter.eixo);
        } else {
          this.filter.eixo = this.eixos.slice();
          this.$emit("changeEixo", this.filter.eixo);
        }
      });
    },
    list() {
      municipioAPI
        .getEixos(this.$store.state.uf)
        .then((resp) => {
          this.eixos = resp.data;
        })
        .catch(() => {
          this.$notification.error("Erro ao carregar");
        });
    },
  },
};
</script>
